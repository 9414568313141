import { useQuery } from 'react-query'
import { apiAuth } from 'services/apis/apiAuth'

export type EventsData = {
  value: string
  label: string
}

async function retriveEvents(): Promise<EventsData[]> {
  const { data } = await apiAuth.get('events')

  const events = data?.map((event: any) => {
    return {
      value: String(event.id),
      label: event.description
    }
  })

  return events
}

export const useEvents = () => {
  return useQuery('events', () => retriveEvents())
}
