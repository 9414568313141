import {
  useQuery,
  useMutation as MutationRQ,
  UseMutationResult,
  useInfiniteQuery
} from 'react-query'
import { apiAuth } from 'services/apis/apiAuth'

export type GuidelineData = {
  procedure_name: string
  id: number
  event_ids: number[]
  description: string
  client_ids: number[]
  procedure_code: number | string
}

type RetriveGuidelinesReturn = {
  guidelines: GuidelineData[]
  totalCount: string
  page: number
}

async function retriveGuidelines(
  { pageParam = 1 },
  search: string,
  filters: number[]
): Promise<RetriveGuidelinesReturn> {
  const res = await apiAuth.get(`guidelines?page=${pageParam}`, {
    params: {
      ...(!!search && { t: search }),
      ...(!!filters.length && { client_ids: filters.join(',') })
    }
  })

  console.log('retrive', res)

  return {
    guidelines: res.data,
    totalCount: res.headers['x-total-count'],
    page: pageParam
  }
}

export async function removeGuideline(id: number): Promise<void> {
  await apiAuth.delete(`guidelines/${id}`)
}

export function createGuideline(): UseMutationResult {
  return MutationRQ(async (payload) => {
    console.log('criacao', payload)
    const res = await apiAuth.post('/guidelines', payload)
    return res
  })
}

export function updateGuideline(id: number): UseMutationResult {
  return MutationRQ(async (payload) => {
    const res = await apiAuth.put(`guidelines/${id}`, payload)

    return res
  })
}

export const useGuidelineService = (search: string, filters: number[]) => {
  return useInfiniteQuery(
    'guidelines',
    (props) =>
      retriveGuidelines({ pageParam: props.pageParam }, search, filters),
    {
      getNextPageParam: (lastPage) => {
        const totalPages = Math.ceil(Number(lastPage.totalCount) / 10)

        if (lastPage.page < totalPages) return lastPage.page + 1

        return false
      }
    }
  )
}
