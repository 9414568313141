import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  max-width: 32.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h2`
  text-align: center;
`

export const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black[56]};
    text-align: center;
  `}
`
