export function validateEmail(email: string) {
  const emailMaida = [
    /^[a-z0-9.]+@maida\.health$/i,
    /^[a-z0-9.]+@infoway-pi\.com\.br$/i,
    /^[a-z0-9.]+@hapvida\.com$/i,
    /^[a-z0-9.]+@maidahealth\.com$/i
  ]

  let isErro = ''

  for (const i in emailMaida) {
    if (email.match(emailMaida[i])?.index !== undefined) {
      isErro = ''
      break
    } else {
      isErro = 'E-mail não suportado'
    }
  }

  return isErro
}
