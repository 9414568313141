import { useState, useRef, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { convertFromRaw, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'

// icons
import IconBold from 'assets/img/draft-bold.svg'
import IconUnderline from 'assets/img/draft-underline.svg'
import IconItalic from 'assets/img/draft-italic.svg'

import * as S from './styles'

type Props = {
  getValue?: (value: any) => void
  defaultValue?: any
}

export const Draft = ({ getValue, defaultValue = '' }: Props) => {
  const ref = useRef(null)
  const [value, setValue] = useState<any>()

  const onContentStateChange = (contentState: any) => {
    // setValue(contentState)

    !!getValue && getValue(stateToHTML(convertFromRaw(contentState)))
  }

  useEffect(() => {
    if (defaultValue && !value) {
      const contentStateAux = stateFromHTML(defaultValue)
      setValue(convertToRaw(contentStateAux))
    }
  }, [defaultValue])

  return (
    <S.Wrapper>
      <Editor
        ref={ref}
        contentState={value}
        onContentStateChange={onContentStateChange}
        toolbar={{
          options: ['inline', 'list'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
            bold: { icon: IconBold },
            italic: { icon: IconItalic },
            underline: { icon: IconUnderline }
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered']
          }
        }}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    </S.Wrapper>
  )
}
