import * as S from './styles'
import Lottie from 'react-lottie'
import AnimationData from 'assets/lottie/success-animation.json'

type Props = {
  title: string
  subTitle: string
}

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: AnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const FeedbackSend = ({ title, subTitle }: Props) => (
  <S.Wrapper>
    <Lottie options={defaultOptions} height={128} width={128} />
    <S.Title>{title}</S.Title>
    <S.SubTitle>{subTitle}</S.SubTitle>
  </S.Wrapper>
)

export default FeedbackSend
