/* eslint-disable prettier/prettier */
import styled, { css, DefaultTheme } from 'styled-components'
import { StateTypes } from '.'

// MODIFIERS
const modifiersState = {
  default: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.maida.primary.default};
    color: ${theme.colors.maida.primary.default};
    fill: ${theme.colors.maida.primary.default};
  `,
  danger: (theme: DefaultTheme) => css`
   border-color: ${theme.colors.danger.medium};
   color: ${theme.colors.danger.medium};
   fill: ${theme.colors.danger.medium};
  `,
  success: (theme: DefaultTheme) => css`
   border-color: ${theme.colors.success.medium};
   color: ${theme.colors.success.medium};
   fill: ${theme.colors.success.medium};
  `,
  octopus: (theme: DefaultTheme) => css`
   border-color: ${theme.colors.maida.primary.default};
   color: ${theme.colors.maida.primary.default};
   fill: ${theme.colors.maida.primary.default};
  `
}

//TYPES
type WrapperProps = {
  isFill: boolean
  isFocus: boolean
  isIconLeft: boolean
  state: StateTypes
}

type AssistencTextProps = Pick<WrapperProps, 'state'>

// COMPONENTES
export const Label = styled.label`
  ${({ theme }) => css`
    position: absolute;
    left: 8px;
    top: calc(50% - 10px);
    color: #aaa;

    font-size: 1.6rem;
    color: ${theme.colors.black[56]};
    font-weight: 400;
    padding: 0 0.8rem;

    transition: all 0.1s ease;

    pointer-events: none;
  `}
`

export const Input = styled.input`
  position: relative;
  border: none;
  background: transparent;

  transition: all 0.2s ease;

  height: 100%;
  width: 100%;

  outline: none;

  font-size: 1.6rem;
`

export const IconLeft = styled.div`
    ${({theme}) => css`
        margin-right: 0.8rem;
        margin-top: 6px;

        svg path {
            fill: ${theme.colors.maida.primary.default};
        }
    `}
`

type ButtonPasswordProps = {
  state: StateTypes
}

export const ButtonPassword = styled.div<ButtonPasswordProps>`
  ${({theme, state}) => css`
      border: none;
      background: transparent;
      margin: 0;
      padding: 0;
      outline: none;
      margin-top: 2px;

      margin-left: 0.8rem;

      svg path {
          fill: ${theme.colors.maida.primary.default};
      }

      ${state === 'octopus' && css`
        svg path {
            fill: ${theme.colors.maida.primary.default};
        }
      `}
  `}
`

export const ButtonIconRight = styled.button<ButtonPasswordProps>`
  ${({theme, state}) => css`
      border: none;
      background: transparent;

      cursor: pointer;

      margin: 0;
      padding: 0;
      outline: none;
      margin-top: 2px;

      margin-left: 0.8rem;

      svg path {
          fill: ${theme.colors.maida.primary.default};
      }

      ${state === 'octopus' && css`
        svg path {
            fill: ${theme.colors.maida.primary.default};
        }
      `}
  `}
`


export const WrapperLeft = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
`

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isFill, isFocus,isIconLeft, state }) => css`
    width: 100%;
    position: relative;

    height: 5.6rem;

    padding: 0 1.6rem;
    border: 1px solid ${theme.colors.black[40]};
    border-radius: 0.4rem;

    display: flex;
    flex-direction: row !important;
    align-items: center;

    background: #fff;

    ${state !== 'default' && css`
      border: 2px solid ${theme.colors.black[40]};
      ${modifiersState[state!](theme)}

      input::placeholder {
        ${modifiersState[state!](theme)}
      }

      ${Label} {
        ${modifiersState[state!](theme)}
      }

      ${IconLeft} {
        svg path {
          ${modifiersState[state!](theme)}
        }
      }
    `}

    ${isIconLeft && css`
        ${Label} {
            left: 40px;
        }
    `}

    ${(isFill) &&
    css`
      border: 1px solid ${theme.colors.black[40]};



      ${Input} {
        border: none;
      }

      ${Label} {
        top: -10px;
        left: 8px;
        font-size: 1.4rem;

        z-index: 2;
        background-color: #fafafa;
        padding: 0 0.4rem;

        background: linear-gradient(transparent 41%, #fff 41%, #fff 59%);

        color: ${theme.colors.black[56]};
      }

      ${state !== 'default' && css`
        border-width: 2px;
        ${modifiersState[state!](theme)}

        ${Label} {
          ${modifiersState[state!](theme)}
        }
      `}
    `}

    ${(isFocus) &&
    css`
      border: 2px solid ${theme.colors.maida.primary.default};

      ${modifiersState[state!](theme)}

      ${Input} {
        border: none;
      }

      ${Label} {
        top: -10px;
        left: 8px;
        font-size: 1.4rem;

        z-index: 2;
        background-color: #fafafa;
        padding: 0 0.4rem;

        background: linear-gradient(transparent 41%, #fff 41%, #fff 59%);

        color: ${theme.colors.maida.primary.default};

        ${modifiersState[state!](theme)}
      }
    `}





  `}
`

export const ContentAssistiveText = styled.span<AssistencTextProps>`
  ${({ theme, state}) => css`
    font-size: 1.4rem;
    margin-left: 1.6rem;
    color: ${theme.colors.black[56]}

    ${state !== 'default' && css`
      ${modifiersState[state!](theme)}
    `}
  `}
`
