import * as S from './styles'
import Lottie from 'react-lottie'

import LottieLoading from 'assets/lottie/loading.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LottieLoading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export type LoadingProps = {
  size?: 'default' | 'small'
}

const Loading = ({ size = 'default' }: LoadingProps) => (
  <S.Wrapper size={size}>
    <Lottie options={defaultOptions} isStopped={false} isPaused={false} />
  </S.Wrapper>
)

export default Loading
