import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'

interface RouteVisitantProps extends RouteProps {
  component: React.ComponentType
}

export const RoutePrivate = ({
  component: Component,
  ...rest
}: RouteVisitantProps) => {
  const { user } = useAuth()
  const userAutenticate = !!user

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userAutenticate ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
