import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;

      margin-top: 2.8rem;

      color: ${theme.colors.black[56]};
    }
  `}
`
