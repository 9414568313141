import React from 'react'
import { Modal, Input, Button } from 'maida-ui'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import IconMail from 'assets/img/mai-ic-email.mono.svg'
import IconPassword from 'assets/img/mai-ic-password.mono.svg'

import * as S from './styles'
import { useEffect } from 'react'
import { OctopusSendTokenCredentials, useAuth } from 'hooks/auth'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
}

const initialValues = {
  email: '',
  password: ''
}

export const ModalSignInOct = ({ isOpen, onRequestClose }: Props) => {
  const { handleSubmit, formState } = useForm()
  const { signInOctopus, octopusSendTokenRequest } = useAuth()
  const navigation = useHistory()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErros] = React.useState(initialValues)
  const [activedButton, setActivedButton] = React.useState(false)

  function resetValues(): void {
    setEmail('')
    setPassword('')
    setErros(initialValues)
  }

  async function requestOctToken(values: OctopusSendTokenCredentials) {
    try {
      await octopusSendTokenRequest.mutateAsync(values)

      setTimeout(() => {
        navigation.push('/homepage')
      }, 3000)
    } catch (err) {
      if (err.response.status === 401) {
        setErros({
          ...errors,
          email: 'Verifique seu email!',
          password: 'Verifique sua senha!'
        })
      }
    }
  }

  const onSign: SubmitHandler<any> = async () => {
    try {
      const data = await signInOctopus.mutateAsync({ password, email })

      const headers = {
        acessToken: data.headers['access-token'],
        client: data.headers.client,
        uid: data.headers.uid
      }

      requestOctToken(headers)
    } catch (err) {
      if (err.response.status === 401) {
        setErros({
          ...errors,
          email: 'Verifique seu email!',
          password: 'Verifique sua senha!'
        })
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      resetValues()
    }
  }, [isOpen])

  React.useEffect(() => {
    const rule1 = !password || !email
    const rule2 = !!errors.email || !!errors.password

    setActivedButton(rule1 || rule2)
  }, [errors, email, password])

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <S.Container>
        <h1>Preencha os seus dados de login do Octopus</h1>
        <S.WrapperFormOCT onSubmit={handleSubmit(onSign)}>
          <div className="wrapper-formoct--inputs">
            <Input
              iconLeft={IconMail}
              isDefault="default"
              label="e-mail"
              value={email}
              assistiveText={errors.email ? errors.email : ''}
              handleOnFocus={() => setErros({ ...errors, email: '' })}
              state={errors.email ? 'danger' : 'octopus'}
              handleOnChange={(value: string) => setEmail(value)}
            />
            <Input
              iconLeft={IconPassword}
              isDefault="default"
              label="senha"
              value={password}
              type="password"
              iconPassword
              assistiveText={errors.password ? errors.password : ''}
              handleOnFocus={() => setErros({ ...errors, password: '' })}
              state={errors.password ? 'danger' : 'octopus'}
              handleOnChange={(value: string) => setPassword(value)}
            />
          </div>

          <div className="wrapper-formoct--button">
            <Button
              themeButton="octopus"
              type="submit"
              disabled={activedButton || formState.isSubmitting}
            >
              {formState.isSubmitting ? 'Carregando...' : 'Entrar'}
            </Button>
          </div>
        </S.WrapperFormOCT>
      </S.Container>
    </Modal>
  )
}
