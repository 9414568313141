import React, { FormEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { Input, Button } from 'maida-ui'
import { useForm } from 'react-hook-form'

import IconMail from 'assets/img/mai-ic-email.mono.svg'

import * as S from './styles'

import { PrimaryTemplate } from 'components/templates/PrimaryTemplate'
import HeadingArrowBack from 'components/atoms/HeadingArrowBack'

import { validateEmail } from 'utils/validationEmails'
import FeedbackSend from 'components/FeedbackSend'
import { useAuth } from 'hooks/auth'

const initialValues = {
  email: ''
}

export const ForgotPassword: React.FC = () => {
  const { handleSubmit, formState } = useForm()
  const navigation = useHistory()
  const [email, setEmail] = React.useState('')
  const [errors, setErros] = React.useState(initialValues)
  const [isShowFeedback, setIsShowFeedback] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { forgotPassword } = useAuth()

  function handleInputEmail(value: string): void {
    setEmail(value)
    setErros({
      ...errors,
      email: ''
    })

    if (value.length > 0) {
      const error = validateEmail(value)

      setErros({
        ...errors,
        email: String(error) || ''
      })
    }
  }

  const onForgotPassword = (event: FormEvent) => {
    event.preventDefault()

    setLoading(true)

    forgotPassword.mutateAsync({ email })

    setTimeout(() => {
      setIsShowFeedback(true)
      setLoading(false)
    }, 800)

    setTimeout(() => {
      navigation.push('/')
      setIsShowFeedback(false)
    }, 3000)
  }

  return (
    <PrimaryTemplate>
      {!isShowFeedback && (
        <S.WrapperForm onSubmit={onForgotPassword}>
          <div className="wrapper-form--text">
            <HeadingArrowBack label="Esqueci minha senha" to="/" />
            <p>
              Insira abaixo o seu e-mail cadastrado no MedicalBook e nós
              enviaremos as instruções para redefinir senha.
            </p>
          </div>

          <div className="wrapper-form--forms">
            <div className="wrapper-form--forms--input">
              <Input
                iconLeft={IconMail}
                isDefault="default"
                label="e-mail"
                value={email}
                assistiveText={errors.email ? errors.email : ''}
                state={errors.email ? 'danger' : 'default'}
                handleOnChange={(value: string) => handleInputEmail(value)}
              />
            </div>

            <S.WrapperButton style={{ marginTop: 24 }}>
              <Button
                type="submit"
                disabled={!email || !!errors.email || loading}
              >
                {loading ? 'Carregando' : 'RECUPERAR SENHA'}
              </Button>
            </S.WrapperButton>
          </div>
        </S.WrapperForm>
      )}

      {isShowFeedback && (
        <S.WrapperFeedback>
          <FeedbackSend
            title="Instruções enviadas"
            subTitle="Enviamos para você um e-mail contendo as instruções para redefinir a sua senha."
          />
        </S.WrapperFeedback>
      )}

      <S.WrapperFooter>
        <p>
          Produto <span>maida.health</span>.
        </p>
      </S.WrapperFooter>
    </PrimaryTemplate>
  )
}
