import { ReactSVG } from 'react-svg'

import LogoMaida from 'assets/logo/logo-large.color.maida.svg'

import * as S from './styles'

const Logo = () => (
  <S.Wrapper>
    <ReactSVG src={LogoMaida} wrapper="span" />
  </S.Wrapper>
)

export default Logo
