import styled, { css } from 'styled-components'
import * as StylesViewDetails from './ViewDetails/styles'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  background: #fafafa;

  width: 100vw;
  height: 100vh;

  overflow: auto;

  background: #fff;

  position: relative;

  ${media.lessThan('medium')`
    display: flex;
  `}
`

export const Content = styled.div`
  max-width: 1286px;
  width: 100%;

  margin: 0 auto;

  padding: 20px 40px;

  ${media.lessThan('medium')`
    padding: 16px;
  `}
`

export const ContentTop = styled.div`
  margin-bottom: 45px;

  display: flex;
  align-items: center;
`

export const ContentBottom = styled.form`
  margin-top: 3.2rem;

  display: flex;
  flex-direction: row;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const WrapperLeft = styled.div`
  ${({ theme }) => css`
    width: 50%;

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.6rem;

      color: ${theme.colors.maida.primary.default};

      margin-bottom: 0.8rem;
    }

    p {
      padding: 0;
      font-size: 1.6rem;
      line-height: 2.4rem;

      text-align: justify;

      & + p {
        margin-top: 2rem;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  `}
`

export const WrapperRight = styled.div`
  width: 50%;

  margin-left: 6rem;

  display: flex;
  flex-direction: column;

  ${StylesViewDetails.Wrapper} {
    flex: 1;
  }

  ${media.lessThan('medium')`
    margin-left: 0;

    margin-top: 1.6rem;
    max-width: 100%;
  `}
`

export const WrapperBTNMore = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;

  margin-top: 2rem;

  button {
    max-width: 450px;
    width: 100%;
  }
`

export const Form = styled.div`
  ${({ theme }) => css`
    div.wrapper__form__input {
      width: 100%;

      > label {
        display: inline-block;

        color: ${theme.colors.black[88]};

        font-size: 1.6rem;
        line-height: 2.4rem;

        font-weight: 600;

        margin-bottom: 0.8rem;
      }

      + div.wrapper__form__input {
        margin-top: 1.6rem;
      }

      &.description__container {
      }
    }
  `}
`

export const WrapperButtonPreView = styled.button`
  ${({ theme }) => css`
    margin: 1.6rem 0;

    background: transparent;
    border: none;

    font-size: 1.6rem;
    color: ${theme.colors.black[56]};

    span {
      color: ${theme.colors.maida.primary.default};
    }
  `}
`

export const WrapperButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-top: 2.4rem;

    div.wrapper__button__view {
      width: 21.3rem;

      button {
        width: 100%;
      }

      &:nth-child(1) {
        .maida-button--wrapper {
          border-color: rgba(0, 0, 0, 0.56);

          &:hover {
            background: #fff;
          }
        }

        .maida-button--text {
          color: rgba(0, 0, 0, 0.56);
        }
      }
    }

    > div.wrapper__button__view + div.wrapper__button__view {
      margin-left: 1rem;
    }

    ${media.lessThan('medium')`
      flex-direction: column;

      div.wrapper__button__view {
        width: 100%;

        button {
          width: 100%;
        }

        &:nth-child(2) {
          order: -1;
          margin-bottom: 1rem;
        }
      }

      > div.wrapper__button__view + div.wrapper__button__view {
        margin-left: 0;

      }
    `}
  `}
`
