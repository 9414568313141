/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Searchbar } from 'maida-ui'
import { useHistory } from 'react-router-dom'

import { WithAvatarAndLogo } from 'components/templates/WithLogoAndAvatar'
import { ContainerMain } from './ContainerMain'
import * as S from './styles'
import { Card } from './Card'
import { Filter } from './Filter'
import { ContainerMainCreate } from './ContainerMain copy'
import Loading from 'components/Loading'
import { GuidelineData, useGuidelineService } from 'services/guidelines'
import NotFound from 'components/NotFound'
import { useAuth } from 'hooks/auth'
import { queryClient } from 'services/queryClient'

type SeletedClientsData = {
  value: string
  label: string
}

export const Homepage = () => {
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [filters, setFilters] = React.useState<number[]>([])

  const [guidelines, setGuidelines] = React.useState<GuidelineData[]>([])
  const [loadingSearch, setLoadingSearch] = React.useState(false)
  const observer = React.useRef<any>(null)
  const navigation = useHistory()
  const { userData } = useAuth()
  const {
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    isError,
    data,
    refetch,
    isFetching
  } = useGuidelineService(search, filters)

  let debounce: any

  const onSearch = (value: string) => {
    setLoadingSearch(true)
    debounce = clearTimeout()

    setSearch(value)

    debounce = setTimeout(() => {
      queryClient.removeQueries('guidelines')
      setPage(1)
      setGuidelines([])
      refetch()
    }, 800)

    setLoadingSearch(false)
  }

  const onFilter = (option: SeletedClientsData) => {
    if (option.value !== '0') {
      if (filters.indexOf(Number(option.value)) === -1) {
        setFilters((oldState) => [...oldState, Number(option.value)])
      } else {
        const filter = filters.filter((op) => op !== Number(option.value))
        setFilters(filter)
      }
    } else {
      setFilters([])
    }

    setTimeout(() => {
      queryClient.removeQueries('guidelines')
      setPage(1)
      setGuidelines([])
      refetch()
    }, 400)
  }

  /**
   * LOGICA PARA INFITE SCROLL
   */
  const lastElementRef = React.useCallback(
    (node) => {
      if (isLoading) return

      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          guidelines.length < Number(data?.pages[0].totalCount) &&
          !search &&
          !filters.length
        ) {
          setPage((prevPage) => prevPage + 1)
          fetchNextPage()
        }
      })

      if (node) observer.current.observe(node)
    },
    [isLoading, data, guidelines]
  )

  React.useEffect(() => {
    if (
      !isLoading &&
      !!data?.pages[page - 1]?.guidelines.length &&
      guidelines.length <= Number(data?.pages[0].totalCount)
    ) {
      setGuidelines((oldState) => [
        ...oldState,
        ...data?.pages[page - 1].guidelines
      ])
    }
  }, [isLoading, data])

  return (
    <WithAvatarAndLogo>
      <S.Wrapper>
        {userData?.profile !== undefined && userData.profile === 'publisher' ? (
          <ContainerMainCreate />
        ) : (
          <ContainerMain />
        )}

        <S.WrapperSearchAndFilter>
          <div className="wrapperSearchAndFilter--searchbar">
            <Searchbar
              placeholder="Digite código ou nome da diretriz"
              handleOnChange={({
                target: { value }
              }: React.ChangeEvent<HTMLInputElement>) => onSearch(value)}
            />
          </div>

          <div className="wrapperSearchAndFilter--filter">
            <Filter
              setSelectedClients={(option: SeletedClientsData) =>
                onFilter(option)
              }
            />
          </div>
        </S.WrapperSearchAndFilter>

        <S.Content>
          {!isLoading &&
            (isError || data?.pages[0].guidelines.length === 0) &&
            !loadingSearch && (
              <S.WrapperLoading>
                <NotFound label="Nenhuma diretriz foi cadastrada ainda." />
              </S.WrapperLoading>
            )}

          {!!data?.pages.length && !loadingSearch && (
            <>
              {data?.pages.map((pageContent) => (
                <>
                  {pageContent.guidelines.map((guideline, index) => {
                    if (pageContent.guidelines.length === index + 1) {
                      // caso seja ultimo recebe ref
                      return (
                        <Card
                          key={String(guideline.id)}
                          onClick={() =>
                            navigation.push({
                              pathname: `/diretriz/${guideline.id}`,
                              state: guideline
                            })
                          }
                          label={guideline.procedure_name}
                          guideline={guideline}
                          ref={lastElementRef}
                        />
                      )
                    }
                    return (
                      <Card
                        key={guideline.id}
                        onClick={() =>
                          navigation.push({
                            pathname: `/diretriz/${guideline.id}`,
                            state: guideline
                          })
                        }
                        label={guideline.procedure_name}
                        guideline={guideline}
                      />
                    )
                  })}
                </>
              ))}
            </>
          )}

          {(isLoading || isFetchingNextPage || loadingSearch) && (
            <S.WrapperLoading>
              <Loading />
            </S.WrapperLoading>
          )}
        </S.Content>
      </S.Wrapper>
    </WithAvatarAndLogo>
  )
}
