import * as S from './styles'
import { ReactSVG } from 'react-svg'

import ImgSearch from 'assets/illustrations/mai-illustration-search.svg'

export type NotFoundProps = {
  label: string
}

const NotFound = ({ label }: NotFoundProps) => (
  <S.Wrapper>
    <ReactSVG src={ImgSearch} />

    <p>{label}</p>
  </S.Wrapper>
)

export default NotFound
