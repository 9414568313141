import axios from 'axios'

//HOMOLOGACAO
const BASE_URL = 'https://octopushomologacao.herokuapp.com/api/v2'

// PROD
// const BASE_URL = 'https://theoctopus.com.br/api/v2';

export const apiOct = axios.create({
  baseURL: BASE_URL
})
