import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    p.title {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: ${theme.colors.maida.primary.default};
      padding-bottom: 0.8rem;
      text-transform: uppercase;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: ${theme.colors.black[88]};
      margin: 0;
      padding: 0;
    }
  `}
`

export const WrapperIllustration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 3.4rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white.bg};
    padding: ${theme.spacings.small};

    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: ${theme.border.radius};

    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.6rem;
      line-height: 2.4rem;

      margin-bottom: 2.4rem;
    }

    ${Item} + ${Item} {
      margin-top: 1.6rem;
    }
  `}
`
