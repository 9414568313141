import { App } from 'App'
import { ThemeProvider } from 'styled-components'
import { MaidaUIProvider } from 'maida-ui'

import React from 'react'
import ReactDOM from 'react-dom'
import GlobalStyles from 'styles/global'
import theme from 'styles/theme'
import reportWebVitals from './reportWebVitals'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'services/queryClient'
import { AuthProvider } from 'hooks/auth'

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
