import React from 'react'
import { Avatar } from 'maida-ui'

import Logo from 'components/Logo'

import IconClose from 'assets/imgs/mai-ic-close.svg'
import IconEdit from 'assets/imgs/mai-ic-edit.svg'

import * as S from './styles'
import { useAuth } from 'hooks/auth'
import { DrawerProfile } from './DrawerProfile'

type Props = {
  children: React.ReactNode
}

type DropdownData = {
  value: string
  label: string
}

const optionDropdownAvatar = [
  { icon: IconEdit, value: '1', label: 'Editar meus dados' },
  { icon: IconClose, value: '2', label: 'Sair', color: '#F44336' }
]

export const WithAvatarAndLogo = ({ children }: Props) => {
  const { signOut } = useAuth()
  const [isOpen, setIsOpen] = React.useState(false)

  function handleSelectedValueDropdown(option: DropdownData) {
    if (option.value === '2') {
      signOut()
    } else {
      setIsOpen(true)
    }
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.ContentTop>
          <div className="wrapper--logo">
            <Logo />
          </div>

          <div className="wrapper--avatar">
            <Avatar
              data={optionDropdownAvatar}
              clicked={(value: DropdownData) =>
                handleSelectedValueDropdown(value)
              }
              isActivedDropdown
            />
          </div>
        </S.ContentTop>

        <DrawerProfile
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
        />

        <S.ContentBottom className="content-tt">{children}</S.ContentBottom>
      </S.Content>
    </S.Wrapper>
  )
}
