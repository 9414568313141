import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid rgba(0, 0, 0, 0.16);
    min-height: 34rem;

    border-radius: ${theme.border.radius};

    .toolbarClassName {
      border: none;

      padding-top: 1rem;

      .rdw-option-wrapper {
        border: none;
      }

      .rdw-option-active {
        border: 1px solid ${theme.colors.black[56]};
      }
    }

    .wrapperClassName {
    }

    .editorClassName {
      padding: 0 16px;
      cursor: text;
    }
  `}
`
