import React from 'react'
import { ReactSVG } from 'react-svg'

import { useClientService } from 'services/clients'
import { useEvents } from 'services/events'

import Illustrations from 'assets/illustrations/guideline-viewdetails.svg'

import * as S from './styles'

type ViewDetailsProps = {
  procedureCode: string | number
  procedureName: string
  events: number[]
  clients: number[]
}

export const ViewDetails = ({
  procedureCode,
  procedureName,
  events,
  clients
}: ViewDetailsProps) => {
  const { isLoading, data } = useClientService()
  const { isLoading: isLoadingEvents, data: dataEvents } = useEvents()
  const [clientsFormated, setClients] = React.useState('')
  const [eventsFormated, setEvents] = React.useState('')

  React.useEffect(() => {
    if (clientsFormated.length === 0 && data?.length > 0) {
      const dataFormat = data?.filter(
        (client: any) => clients.indexOf(Number(client.id)) !== -1
      )
      const labelArrayFormated = dataFormat.map((client: any) => client.company)

      setClients(labelArrayFormated.join(', '))
    }
  }, [data])

  React.useEffect(() => {
    if (eventsFormated.length === 0 && dataEvents !== undefined) {
      const dataFormat = dataEvents?.filter(
        (event) => events.indexOf(Number(event.value)) !== -1
      )
      const labelArrayFormated = dataFormat.map((event) => event.label)

      setEvents(labelArrayFormated.join(', '))
    }
  }, [dataEvents])

  return (
    <S.Wrapper>
      <h3>Informações</h3>
      <S.Item>
        <p className="title">CÓDIGO DO PROCEDIMENTO</p>
        <p>{procedureCode}</p>
      </S.Item>
      <S.Item>
        <p className="title">NOME DO PROCEDIMENTO</p>
        <p>{procedureName}</p>
      </S.Item>
      <S.Item>
        <p className="title">EVENTO DE ATUAÇÃO</p>
        <p>{isLoadingEvents ? 'Buscando informações...' : eventsFormated}</p>
      </S.Item>
      <S.Item>
        <p className="title">CLIENTE VINCULADO A ESSA DIRETRIZ</p>
        <p>{isLoading ? 'Buscando informações...' : clientsFormated}</p>
      </S.Item>

      <S.WrapperIllustration>
        <ReactSVG src={Illustrations} />
      </S.WrapperIllustration>
    </S.Wrapper>
  )
}
