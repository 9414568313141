import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  position: relative;
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;

    > p {
      font-weight: 600;
      color: ${theme.colors.black[56]};
      padding: 0;
    }
  `}
`

export const WrapperCounter = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.maida.primary.default};

    width: 2.4rem;
    height: 2.4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: ${theme.border.radius};

    margin-left: 0.8rem;
    margin-right: 1.4rem;

    span {
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${theme.colors.white[100]};
    }
  `}
`

export const BtnArrow = styled.button`
  ${({ theme }) => css`
    border: none;
    background: transparent;

    cursor: pointer;

    svg path {
      fill: ${theme.colors.maida.primary.default};
    }
  `}
`

type DropdownProps = {
  isOpen: boolean
}

export const Dropdown = styled.div<DropdownProps>`
  ${({ theme, isOpen }) => css`
    background: ${theme.colors.white[100]};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: ${theme.border.radius};

    padding: 2rem;

    position: absolute;
    right: 0px;

    z-index: 20;

    margin-top: 16px;

    min-width: 350px;
    max-height: 350px;

    overflow: auto;

    ${isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  `}
`

export const WrapperLoading = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`
