import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react'
import { useToast } from 'maida-ui'
import { useHistory } from 'react-router-dom'
import { apiOct } from 'services/apis/apiOct'
import { useMutation } from 'react-query'
import { apiMB } from 'services/apis/apiMB'
import { apiAuth } from 'services/apis/apiAuth'

interface AuthState {
  token: string
}

interface SignInCredentials {
  email: string
  password: string
}

type ForgotPasswordCredentials = {
  email: string
  redirect_url?: string
}

type EditPasswordData = {
  password: string
  password_confirmation: string
}

export type UserData = {
  email: string
  id: number
  profile: 'publisher' | 'customer' | 'administrator'
  provider: string
  uid: string
  name: string
}

export type OctopusSendTokenCredentials = {
  acessToken: string
  uid: string
  client: string
}

type CreateUserCredentials = {
  name: string
  email: string
  password: string
  confirmationPassword: string
}

type SetLocalstorageUserCredentials = {
  accessToken: string
  client: string
  uid: string
}

interface AuthContextData {
  forgotPassword: any
  user: any
  userData: UserData
  signIn: any
  signOut(): void
  signInOctopus: any
  createUserEmail: any
  octopusSendTokenRequest: any
  userEditData: (name: string) => Promise<void>
  editPassword: (data: EditPasswordData) => Promise<void>
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const navigation = useHistory()
  const { addToast } = useToast()
  const [data, setData] = useState<AuthState | any>(() => {
    const token = localStorage.getItem('@MEDICALBOOK-OCT:token')

    if (token) {
      return { token }
    }

    return {} as AuthState
  })
  const [userData, setUserData] = useState<UserData>(() => {
    const data: any = localStorage.getItem('@MEDICALBOOK-OCT:USER-DATA')
    const datForm = JSON.parse(data)

    if (data) {
      return datForm
    }

    return {} as UserData
  })

  // FUNCAO COM TOAST DE ERRO
  function toastMessage(err: any) {
    if (err.response.status === 401) {
      addToast({
        title: 'Credenciais inválidas! Verifique e tente novamente.',
        type: 'error'
      })
    } else if (err.response.status === 422) {
      addToast({
        title: 'Email já cadastro!',
        type: 'error'
      })
    } else {
      addToast({
        title: 'Erro no servidor! Tente novamente em instântes.',
        type: 'error'
      })
    }
  }

  function setLocaltorageUser(headers: SetLocalstorageUserCredentials) {
    const formatHeaders = {
      ['access-token']: headers.accessToken,
      client: headers.client,
      uid: headers.uid
    }

    setData({ token: headers })

    localStorage.setItem(
      '@MEDICALBOOK-OCT:token',
      JSON.stringify(formatHeaders)
    )
  }

  function setLocalstorageDataUsers(data: UserData) {
    setUserData(data)

    console.log('dados para serem formatados', data)

    localStorage.setItem('@MEDICALBOOK-OCT:USER-DATA', JSON.stringify(data))
  }

  // P/  SALVAR O USUARIO LOGADO NO LOCALSTORAGE

  const signIn = useMutation(
    async ({ email, password }: SignInCredentials) => {
      console.log('responta login', { email, password })

      const response = await apiMB.post('/auth/sign_in', { email, password })

      return response
    },
    {
      onSuccess: (res) => {
        setLocaltorageUser({
          accessToken: res.headers['access-token'],
          client: res.headers.client,
          uid: res.headers.uid
        })

        setLocalstorageDataUsers(res.data.data)

        addToast({
          title: 'Login realizado com sucesso!',
          type: 'success'
        })
      },
      onError: (err: any) => {
        toastMessage(err)
      }
    }
  )

  const createUserEmail = useMutation(
    async ({
      name,
      email,
      password,
      confirmationPassword
    }: CreateUserCredentials) => {
      await apiMB.post('/auth', {
        name,
        email,
        password,
        confirmationPassword
      })
    },
    {
      onSuccess: () => {
        addToast({
          title: 'Usuário cadastrado com sucesso!',
          type: 'success'
        })
      },
      onError: (err: any) => {
        toastMessage(err)
      }
    }
  )

  const octopusSendTokenRequest = useMutation(
    async (headers: OctopusSendTokenCredentials) => {
      const response = await apiMB.post('/auth/octopus/sign_in', {
        ['access-token']: headers.acessToken,
        client: headers.client,
        uid: headers.uid
      })

      console.log('reposta ao logar com octopus', response)

      return response
    },
    {
      onSuccess: (res) => {
        setLocaltorageUser({
          accessToken: res.data.data['access-token'],
          client: res.data.data.client,
          uid: res.data.data.uid
        })

        setLocalstorageDataUsers(res.data.data)

        addToast({
          title: 'Login realizado com sucesso!',
          type: 'success'
        })
      },
      onError: (err: any) => {
        toastMessage(err)
      }
    }
  )

  // RECUPERACAO DE SENHA

  const forgotPassword = useMutation(
    async (body: ForgotPasswordCredentials) => {
      const response = await apiMB.post('/auth/password', {
        ...body,
        redirect_url: 'http://localhost:3000/nova-senha'
      })

      console.log('ao recuperar a senha', response)

      return response
    },
    {
      onSuccess: (res) => {
        addToast({
          title: 'Email de recuperação enviado com sucesso!',
          type: 'success'
        })
      },
      onError: (err: any) => {
        toastMessage(err)
      }
    }
  )

  // EDITAR DADOS DO USUARIO
  const userEditData = async (name: string) => {
    await apiAuth.put('/users/current', { name })
  }

  // ALTERAR SENHA
  const editPassword = async (data: EditPasswordData) => {
    await apiAuth.put('/auth/password', data)
  }

  const signInOctopus = useMutation(
    async ({ email, password }: SignInCredentials) => {
      const res = await apiOct.post('/autenticar/entrar', { email, password })
      return res
    },
    {
      onError: (err: any) => {
        toastMessage(err)
      }
    }
  )

  const signOut = useCallback(() => {
    localStorage.removeItem('@MEDICALBOOK-OCT:token')
    localStorage.removeItem('@MEDICALBOOK-OCT:USER-DATA')
    setData({} as AuthState)
    setUserData({} as UserData)
    navigation.push('/')
  }, [navigation])

  return (
    <AuthContext.Provider
      value={{
        user: data.token,
        signIn,
        signOut,
        createUserEmail,
        signInOctopus,
        octopusSendTokenRequest,
        userData: userData,
        forgotPassword,
        userEditData,
        editPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  return context
}
