import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;

  overflow: auto;
`

export const Header = styled.header`
  display: flex;
  align-items: center;

  padding: 1.6rem;

  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  background: #fff;

  position: sticky;
  top: 0;
  z-index: 30;

  > button {
    background: transparent;
    border: none;

    cursor: pointer;

    svg path {
      fill: #000;
    }
  }

  > h2 {
    padding: 0;
    margin: 0;
    margin-left: 2rem;
    padding-bottom: 2px;
  }
`

export const Content = styled.form`
  width: 360px;

  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`

export const WrapperContent = styled.section`
  margin-top: 2rem;

  padding: 1.6rem;

  > h2 {
  }

  > div.wrapperContent__inputGroup {
    label {
      margin-bottom: 0.8rem;

      color: rgba(0, 0, 0, 0.88);
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;

      display: inline-block;
    }
  }

  > div.wrapperContent__inputGroup + div.wrapperContent__inputGroup {
    margin-top: 1.6rem;
  }
`

export const WrapperButton = styled.footer`
  flex: 1;

  background: #fff;

  position: sticky;
  bottom: 0;
  z-index: 30;

  flex: 1;

  display: flex;
  flex-direction: column;

  justify-content: flex-end;

  div.wrapperButton__btn {
    border-top: 1px solid rgba(0, 0, 0, 0.08);

    width: 100%;
    padding: 1.6rem;

    > button {
      width: 100%;
    }
  }
`
