import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  ${({ theme }) => css`
    h2 {
      font-size: 2rem;
      line-height: 2.4rem;

      color: rgba(0, 0, 0, 0.88);

      padding: 0;
    }

    p {
      color: ${theme.colors.black[56]};

      margin: 2.4rem 0;
      padding: 0;
    }
  `}
`

export const WrapperButtons = styled.div`
  display: flex;

  div.wrapper-btn {
    max-width: 20rem;

    button {
      width: 100%;
    }

    & + div.wrapper-btn {
      margin-left: 1.6rem;
    }
  }

  div.wrapper-btn:nth-child(1) {
    .maida-button--wrapper {
      border-color: rgba(0, 0, 0, 0.56);

      &:hover {
        background: #fff;
      }
    }

    .maida-button--text {
      color: rgba(0, 0, 0, 0.56);
    }
  }

  ${media.lessThan('medium')`
    flex-direction: column;

    div.wrapper-btn {
      max-width: 100%;

      & + div.wrapper-btn {
          margin-left: 0rem;
      }
    }

    div.wrapper-btn:nth-child(2) {
      order: -1;

      margin-bottom: 1rem;
    }

  `}
`
