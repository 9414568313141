import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 144px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: ${theme.colors.maida.primary.default};
    border-radius: ${theme.border.radius};

    padding: ${theme.spacings.small};
  `}
`

export const ContentLeft = styled.div`
  ${({ theme }) => css`
    max-width: 63.2rem;

    h3 {
      padding-bottom: 1.6rem;

      color: ${theme.colors.white[100]};

      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 600;
    }

    p {
      color: ${theme.colors.white[100]};

      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: normal;

      padding: 0;
    }
  `}
`

export const ContentRight = styled.div`
  ${media.lessThan('medium')`
    display: none;
  `}
`
