import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  background: #fff;

  width: 100vw;
  min-height: 100vh;

  ${media.lessThan('medium')`
    display: flex;
  `}
`

export const Content = styled.div`
  max-width: 1286px;
  width: 100%;

  margin: 0 auto;

  padding: 20px 40px;

  ${media.lessThan('medium')`
    padding: 16px;
  `}
`

export const ContentTop = styled.div`
  margin-bottom: 45px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContentBottom = styled.div`
  &.content-tt {
    display: block;
    width: 100%;
  }
`
