import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
`

export const WrapperSearchAndFilter = styled.div`
  margin-top: 4rem;
  width: 100%;

  div.wrapperSearchAndFilter--searchbar {
    div {
      display: flex;
      flex-direction: row;
    }
  }

  div.wrapperSearchAndFilter--filter {
    display: flex;
    justify-content: flex-end;

    margin-top: 3rem;
  }
`

export const Content = styled.div`
  margin-top: 30px;

  width: 100%;

  display: block !important;
  flex-direction: column !important;

  flex: 1;

  > button + button {
    margin-top: 0.8rem;
  }
`

export const WrapperLoading = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`
