import React from 'react'

import { ReactSVG } from 'react-svg'
import { useHistory } from 'react-router-dom'

import Overflow from 'components/molecules/Overflow'
import { useAuth } from 'hooks/auth'
import { ModalDeleteGuideline } from './ModalDeletGuideline'
import { GuidelineData } from 'services/guidelines'

import IconAttachemnt from 'assets/imgs/mai-ic-attachment.svg'
import IconEdit from 'assets/imgs/mai-ic-edit.svg'
import IconTrash from 'assets/imgs/mai-ic-trash.svg'

import * as S from './styles'

type SelectedOverflow = {
  value: string
  label: string
}

type Props = {
  label: string
  onClick?: () => void
  guideline: GuidelineData
}

const options = [
  { value: '1', label: 'Editar', icon: IconEdit },
  { value: '2', label: 'Excluir', icon: IconTrash }
]

const CardBase: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { label, onClick, guideline },
  ref
) => {
  const { userData } = useAuth()
  const navigation = useHistory()
  const [isShowModal, setIsShowModal] = React.useState(false)

  console.log('teste', userData.profile)

  const onSelectedOptionOverflow = React.useCallback(
    (opt: SelectedOverflow) => {
      if (opt.value === '1') {
        navigation.push({
          pathname: `/diretrizes/editar/${guideline.id}`,
          state: guideline
        })
      } else {
        setIsShowModal(true)
      }
    },
    [guideline, navigation]
  )

  return (
    <S.Wrapper
      ref={ref}
      {...(userData?.profile !== undefined &&
        userData.profile === 'customer' && { onClick: onClick! })}
    >
      <S.ContentLeft>
        <div className="contentLeft--icon">
          <ReactSVG src={IconAttachemnt} />
        </div>
        <S.Label>{label}</S.Label>
      </S.ContentLeft>
      {userData?.profile !== undefined && userData.profile !== 'customer' && (
        <S.ContentRight>
          <Overflow
            clicked={(value: SelectedOverflow) =>
              onSelectedOptionOverflow(value)
            }
            options={options}
          />

          <ModalDeleteGuideline
            isOpen={isShowModal}
            idGuidelineDelete={guideline.id}
            onRequestClose={() => setIsShowModal(!isShowModal)}
          />
        </S.ContentRight>
      )}
    </S.Wrapper>
  )
}

export const Card = React.memo(
  React.forwardRef(CardBase),
  (prevProps, nextProps) => {
    return Object.is(prevProps.guideline, nextProps.guideline)
  }
)
