import styled, { css } from 'styled-components'

export const Wrapper = styled.button`
  ${({ theme }) => css`
    width: 100%;

    display: flex;
    align-items: center;

    padding: 18px;
    box-sizing: border-box;
    border-radius: ${theme.border.radius};
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: ${theme.colors.white[100]};

    cursor: pointer;
  `}
`

export const ContentLeft = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    width: 100%;

    svg path {
      fill: ${theme.colors.maida.primary.default};
    }
  `}
`

export const Label = styled.p`
  ${({ theme }) => css`
    padding: 0;
    color: ${theme.colors.black[88]};

    margin-left: 1.8rem;

    max-width: 90rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -moz-binding: url('assets/xml/ellipsis.xml#ellipsis');
    white-space: nowrap;

    text-align: left;
  `}
`

export const ContentRight = styled.div`
  ${({ theme }) => css`
    flex: 1;

    .maida--ui--overflow {
      .overflow__icon {
      }

      .maida-ui-dropdown--wrapper {
        width: 24rem !important;

        & path {
          opacity: 1;
        }
      }
    }
  `}
`
