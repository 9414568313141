import axios from 'axios'

//HOMOLOGACAO
const BASE_URL = 'https://medicalbook-api.dokku.maida.health/v1'

export const apiAuth = axios.create({
  baseURL: BASE_URL
})

// COLOCANDO TOKEN NA REQUEST
apiAuth.interceptors.request.use((req) => {
  const token: any = localStorage.getItem('@MEDICALBOOK-OCT:token')
  const formatToken = JSON.parse(token)

  req.headers = {
    ...formatToken
  }

  return req
})
