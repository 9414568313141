import React from 'react'
import { useHistory } from 'react-router-dom'
import { Input, Button } from 'maida-ui'
import { useForm } from 'react-hook-form'

import IconPassword from 'assets/img/mai-ic-password.mono.svg'

import * as S from './styles'

import { PrimaryTemplate } from 'components/templates/PrimaryTemplate'
import HeadingArrowBack from 'components/atoms/HeadingArrowBack'

import FeedbackSend from 'components/FeedbackSend'

const initialValues = {
  password: '',
  confirmationPassword: ''
}

export const ResetPassword: React.FC = () => {
  const { handleSubmit, formState } = useForm()
  const navigation = useHistory()
  const [password, setPassword] = React.useState('')
  const [confirmationPassword, setConfirmationPassword] = React.useState('')
  const [errors, setErros] = React.useState(initialValues)
  const [isShowFeedback, setIsShowFeedback] = React.useState(false)
  const [activedButton, setActivedButton] = React.useState(false)

  function handleInputPassword(value: string) {
    setPassword(value)
    setErros({
      ...errors,
      password: ''
    })

    if (value.length > 0) {
      setErros({
        ...errors,
        password: 'Senha deve ter no mínimo 6 caracteres '
      })

      if (value.length > 5) {
        setErros({
          ...errors,
          password: ''
        })
      }
    }
  }

  function handleInputConfirmationPassword(value: string) {
    setConfirmationPassword(value)
    setErros({
      ...errors,
      confirmationPassword: ''
    })

    if (value.length > 0) {
      if (value !== password) {
        setErros({
          ...errors,
          confirmationPassword: 'Confirmação de senha não coincide com a senha'
        })
      }
    }
  }

  const onResetPassword = () => {
    new Promise((resolve) => setTimeout(resolve, 2000))

    setIsShowFeedback(true)

    setTimeout(() => {
      navigation.push('/')
      setIsShowFeedback(false)
    }, 3000)
  }

  React.useEffect(() => {
    const rule1 = !password || !confirmationPassword
    const rule2 = !!errors.password || !!errors.confirmationPassword

    setActivedButton(rule1 || rule2)
  }, [errors, password, confirmationPassword])

  return (
    <PrimaryTemplate>
      {!isShowFeedback && (
        <S.WrapperForm>
          <div className="wrapper-form--text">
            <HeadingArrowBack label="Redefinir senha" to="/" />
            <p>Insira abaixo a sua nova senha de acesso no MedicalBook.</p>
          </div>

          <div className="wrapper-form--forms">
            <div className="wrapper-form--forms--input">
              <Input
                iconLeft={IconPassword}
                isDefault="default"
                label="nova senha"
                value={password}
                type="password"
                iconPassword
                assistiveText={errors.password ? errors.password : ''}
                state={errors.password ? 'danger' : 'default'}
                handleOnChange={(value: string) => handleInputPassword(value)}
              />
            </div>

            <div className="wrapper-form--forms--input">
              <Input
                iconLeft={IconPassword}
                isDefault="default"
                label="confirmar senha"
                value={confirmationPassword}
                type="password"
                iconPassword
                assistiveText={
                  errors.confirmationPassword ? errors.confirmationPassword : ''
                }
                state={errors.confirmationPassword ? 'danger' : 'default'}
                handleOnChange={(value: string) =>
                  handleInputConfirmationPassword(value)
                }
              />
            </div>

            <S.WrapperButton style={{ marginTop: 24 }}>
              <Button
                onClick={handleSubmit(onResetPassword)}
                disabled={activedButton}
              >
                {formState.isSubmitting ? 'Carregando' : 'cadastrar nova senha'}
              </Button>
            </S.WrapperButton>
          </div>
        </S.WrapperForm>
      )}

      {isShowFeedback && (
        <S.WrapperFeedback>
          <FeedbackSend
            title="Senha cadastrada"
            subTitle="Sua nova senha foi cadastrada, utilize-a no seu próximo acesso."
          />
        </S.WrapperFeedback>
      )}

      <S.WrapperFooter>
        <p>
          Produto <span>maida.health</span>.
        </p>
      </S.WrapperFooter>
    </PrimaryTemplate>
  )
}
