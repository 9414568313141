import React, { FormEvent } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Button, Input, useToast } from 'maida-ui'
import { useMediaQuery } from 'react-responsive'

import { useClientService } from 'services/clients'
import { useEvents } from 'services/events'
import {
  createGuideline,
  GuidelineData,
  updateGuideline
} from 'services/guidelines'
import { queryClient } from 'services/queryClient'

import { Draft } from 'components/molecules/Draft'
import HeadingArrowBack from 'components/atoms/HeadingArrowBack'
import { SelectCheckbox } from 'components/molecules/SelectCheckbox'
import { ViewDetails, ViewDetailsCreateProps } from './ViewDetails'
import { ModalViewDetails } from './ModalViewDetails'

import * as S from './styles'

type EventsData = {
  value: string
  label: string
}

type UseParamsResults = {
  id?: string | undefined
}

export const GuidelineCreate = () => {
  const navigation = useHistory()
  const params = useParams<UseParamsResults>()
  const { state: guidelineValue } = useLocation<GuidelineData>()
  const { addToast } = useToast()

  const { isLoading, data } = useClientService()
  const { isLoading: isLoadingEvents, data: dataEvents } = useEvents()
  const { mutateAsync: mutateAsyncCreate } = createGuideline()
  const { mutateAsync: mutateAsyncUpdate } = updateGuideline(
    params.id !== undefined ? Number(params?.id) : 0
  )

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const [showMoreInfo, setShowMoreInfo] = React.useState(false)
  const [isOpenModalView, setIsOpenModalView] = React.useState(false)
  const [clients, setClients] = React.useState<EventsData[]>([])
  const [loading, setLoading] = React.useState(false)
  const [isActived, setIsActived] = React.useState<string[]>([])
  const [fieldsValues, setFieldsValues] = React.useState<any>({} as any)
  const [valuesViewContainer, setValuesViewContainer] =
    React.useState<ViewDetailsCreateProps>({
      procedureCode: '',
      procedureName: '',
      events: '',
      clients: '',
      description: ''
    })

  /**
   * QUANDO DESCRIPTION ESTIVER VAZIO DE => <p><br></br> para => ''
   */
  function removeHTMLIsEmptyValueDesription(valueDefault: string) {
    const v1 = valueDefault.replaceAll('<p>', '')
    const v2 = v1.replaceAll('</p>', '')
    const v3 = v2.replaceAll('<br>', '')
    const value = v3.replaceAll(' ', '')

    return value
  }

  function activedButton() {
    Object.keys(fieldsValues).forEach((field) => {
      if (field === 'description') {
        /**
         * como o formato do description é um html, precisa-se de um tramamento diferente
         */

        const descriptonFormat = removeHTMLIsEmptyValueDesription(
          fieldsValues[field]
        )

        if (
          descriptonFormat.length > 0 &&
          isActived.indexOf(String(field)) === -1
        ) {
          setIsActived((oldState) => [...oldState, String(field)])
        } else if (
          descriptonFormat.length === 0 &&
          isActived.indexOf(String(field)) !== -1
        ) {
          const filter = isActived.filter((value) => value !== String(field))

          setIsActived(filter)
        }
      }

      /*
       *DEMAIS CAMPOS
       */

      if (
        fieldsValues[field].length > 0 &&
        field !== 'description' &&
        isActived.indexOf(String(field)) === -1
      ) {
        setIsActived((oldState) => [...oldState, field])
      } else if (
        fieldsValues[field].length === 0 &&
        field !== 'description' &&
        isActived.indexOf(String(field)) !== -1
      ) {
        const filter = isActived.filter((value) => value !== String(field))

        setIsActived(filter)
      }
    })
  }

  /**
   * MONITORA OS ONCHANGES DOS INPUTS
   */
  function handleFieldsChange(name: string, value: string, ids?: number[]) {
    // para visualizar na viewContainer
    setValuesViewContainer((oldState) => ({ ...oldState, [name]: value }))

    // valores de selects (ids dos clients ou eventops)
    if (ids !== undefined) {
      // para enviar a api
      setFieldsValues((oldState: any) => ({ ...oldState, [name]: ids }))
    } else {
      setFieldsValues((oldState: any) => ({ ...oldState, [name]: value }))
    }
  }

  async function onSubmit(event?: FormEvent) {
    if (event !== undefined) {
      event?.preventDefault()
    }

    setLoading(true)

    const payload = {
      ['procedure_code']: fieldsValues?.procedureCode,
      ['procedure_name']: fieldsValues?.procedureName,
      event_ids: fieldsValues?.events,
      client_ids: fieldsValues?.clients,
      description: fieldsValues?.description
    }

    try {
      if (params?.id !== undefined) {
        await mutateAsyncUpdate(payload, {
          onSuccess: () => {
            queryClient.removeQueries('guidelines')

            addToast({
              title: 'Diretriz cadastrada com sucesso!',
              type: 'success'
            })

            setTimeout(() => {
              navigation.push('/homepage')
            }, 2000)
          },
          onError: (res: any) => {
            if (res?.response?.status === 500) {
              addToast({
                title:
                  'Ocorreu um erro nos nosso servidores! Tente novamente em instantes.',
                type: 'error'
              })
            } else {
              addToast({
                title:
                  'Ocorreu um erro com os dados! Verifique seus dados e tente novamente!',
                type: 'error'
              })
            }
          }
        })
      } else {
        await mutateAsyncCreate(payload, {
          onSuccess: () => {
            addToast({
              title: 'Diretriz cadastrada com sucesso!',
              type: 'success'
            })

            queryClient.removeQueries('guidelines')

            setTimeout(() => {
              navigation.push('/homepage')
            }, 2000)
          },
          onError: (res: any) => {
            if (res.response.status === 500) {
              addToast({
                title:
                  'Ocorreu um erro nos nosso servidores! Tente novamente em instantes.',
                type: 'error'
              })
            } else {
              addToast({
                title:
                  'Ocorreu um erro com os dados! Verifique seus dados e tente novamente!',
                type: 'error'
              })
            }
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }
  // REMOVE DO CACHE DO REACT-QUERY PARA EVITAR ERRO NO INFINITE SCROLL
  React.useEffect(() => {
    queryClient.removeQueries('guidelines')
  }, [])

  /**
   * FORMATAÇÃO PARA CLIENTS DENTRO DO SELECT
   */
  React.useEffect(() => {
    if (clients.length === 0 && data?.length > 0) {
      const dataFormat = data?.map((client: any) => ({
        value: String(client.id),
        label: client.company
      }))

      setClients(dataFormat)
    }
  }, [data])

  // VERIFICA SE TODOS OS CAMPOS ESTÃO ATIVOS PARA ATIVAR O BUTTON DE SUBMIT
  React.useEffect(() => {
    activedButton()
  }, [fieldsValues])

  // CASO SEJA UPDATE COLOCAR OS VALORES DEFAULT
  React.useEffect(() => {
    if (params?.id !== undefined && !!clients.length && !!dataEvents?.length) {
      // tipo === GuidelineData

      // filtra o events e pega o label para colocar no valueViews
      let eventsFormated: any = []
      if (guidelineValue?.event_ids !== undefined) {
        eventsFormated = guidelineValue?.event_ids.map((event: any) => {
          const filter = clients?.find((opt) => {
            return String(opt.value) === String(event)
          })

          return filter
        })
      }

      const formatView = eventsFormated.map((opt: any) => {
        return opt.label
      })

      const idsEvents = eventsFormated.map((opt: any) => {
        return Number(opt.value)
      })

      // filtra o events e pega o label para colocar no valueViews
      let clientsFormated: any = []
      if (guidelineValue?.client_ids !== undefined) {
        clientsFormated = guidelineValue?.client_ids.map((event: any) => {
          const filter = dataEvents?.find((opt) => {
            return String(opt.value) === String(event)
          })

          return filter
        })
      }

      const formatViewClients = clientsFormated.map((opt: any) => {
        return opt.label
      })

      const idsClients = clientsFormated.map((opt: any) => {
        return Number(opt.value)
      })

      // DEFAULT VALUES QUANDO UPDATE

      setValuesViewContainer({
        procedureCode: String(guidelineValue?.procedure_code),
        procedureName: guidelineValue?.procedure_name,
        events: formatView.join(', '),
        clients: formatViewClients.join(', '),
        description: guidelineValue?.description
      })

      setFieldsValues({
        procedureCode: guidelineValue?.procedure_code,
        procedureName: guidelineValue?.procedure_name,
        events: idsEvents,
        clients: idsClients,
        description: guidelineValue?.description
      })

      activedButton()
    }
  }, [clients, dataEvents])

  return (
    <S.Wrapper>
      <S.Content>
        <S.ContentTop>
          <div className="back">
            <HeadingArrowBack
              label={
                params?.id !== undefined
                  ? 'Editar diretriz'
                  : 'Cadastrar nova diretriz'
              }
              to="/homepage"
            />
          </div>
        </S.ContentTop>

        <S.ContentBottom onSubmit={onSubmit}>
          <S.WrapperLeft>
            <S.Form>
              <div className="wrapper__form__input">
                <label className="label">Código do Procedimento</label>
                <Input
                  isDefault="small"
                  name="prodecimentoCode"
                  placeholder="Digite o código"
                  initialValue={fieldsValues?.procedureCode || ''}
                  handleOnChange={(value) =>
                    handleFieldsChange('procedureCode', value)
                  }
                />
              </div>

              <div className="wrapper__form__input">
                <label className="label">Nome do Procedimento</label>
                <Input
                  isDefault="small"
                  name="procedimentoName"
                  placeholder="Digite o nome do procedimento"
                  initialValue={fieldsValues?.procedureName || ''}
                  handleOnChange={(value) =>
                    handleFieldsChange('procedureName', value)
                  }
                />
              </div>

              <div className="wrapper__form__input">
                <label className="label">Evento de atuação</label>
                <SelectCheckbox
                  placeholder="Selecione o(s) evento(s)"
                  initialValue={
                    params?.id !== undefined ? fieldsValues?.events : []
                  }
                  dataDropdown={
                    dataEvents !== undefined
                      ? [{ value: '0', label: 'Todos' }, ...dataEvents]
                      : []
                  }
                  isLoading={isLoadingEvents}
                  name="events"
                  handleSelectedChange={(values) => {
                    handleFieldsChange(
                      'events',
                      values.valueView,
                      values.checkedSeleted
                    )
                  }}
                />
              </div>

              <div className="wrapper__form__input">
                <label className="label">
                  Cliente vinculado a essa diretriz
                </label>
                <SelectCheckbox
                  placeholder="Selecione o(s) cliente(s)"
                  dataDropdown={[{ value: '0', label: 'Todos' }, ...clients]}
                  isLoading={isLoading}
                  initialValue={
                    params?.id !== undefined ? fieldsValues?.clients : []
                  }
                  name="clients"
                  handleSelectedChange={(values) => {
                    handleFieldsChange(
                      'clients',
                      values.valueView,
                      values.checkedSeleted
                    )
                  }}
                />
              </div>

              <div className="wrapper__form__input description__container">
                <label className="label">Descrição do documento</label>
                <Draft
                  getValue={(value: any) =>
                    handleFieldsChange('description', value)
                  }
                  defaultValue={fieldsValues?.description}
                />
              </div>
            </S.Form>
          </S.WrapperLeft>
          {isMobile && !showMoreInfo && (
            <>
              <S.WrapperButtonPreView
                type="button"
                onClick={() => setIsOpenModalView(!isOpenModalView)}
              >
                <span>Visualizar</span> diretriz antes de salvar
              </S.WrapperButtonPreView>

              {isOpenModalView && (
                <ModalViewDetails
                  valuesViewContainer={valuesViewContainer}
                  onRequestClose={() => setIsOpenModalView(!isOpenModalView)}
                  loading={loading}
                  isActived={isActived.length < 5}
                  onSubmit={onSubmit}
                />
              )}

              <S.WrapperButtons>
                <div className="wrapper__button__view">
                  <Button typeButton="ghost">CANCELAR</Button>
                </div>

                <div className="wrapper__button__view">
                  <Button
                    type="submit"
                    disabled={loading || isActived.length < 5}
                  >
                    {loading ? 'ENVIANDO...' : 'SALVAR'}
                  </Button>
                </div>
              </S.WrapperButtons>
            </>
          )}
          <S.WrapperRight>
            {(!isMobile || showMoreInfo) && (
              <>
                <ViewDetails {...valuesViewContainer} />

                <S.WrapperButtons>
                  <div className="wrapper__button__view">
                    <Button
                      typeButton="ghost"
                      type="button"
                      onClick={() => navigation.push('/homepage')}
                    >
                      CANCELAR
                    </Button>
                  </div>
                  <div className="wrapper__button__view">
                    <Button
                      type="submit"
                      disabled={loading || isActived.length < 5}
                    >
                      {loading ? 'ENVIANDO...' : 'SALVAR'}
                    </Button>
                  </div>
                </S.WrapperButtons>
              </>
            )}
          </S.WrapperRight>
        </S.ContentBottom>
      </S.Content>
    </S.Wrapper>
  )
}
