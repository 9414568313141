import { useQuery } from 'react-query'
import { apiAuth } from 'services/apis/apiAuth'

async function retriveClients(): Promise<any> {
  const { data } = await apiAuth.get('/clients')

  return data
}

export const useClientService = () => {
  return useQuery('clients', () => retriveClients())
}
