import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  background: #fafafa;

  width: 100vw;
  min-height: 100vh;

  background: #fff;

  ${media.lessThan('medium')`
    display: flex;
  `}
`

export const Content = styled.div`
  max-width: 1286px;
  width: 100%;

  margin: 0 auto;

  padding: 20px 40px;

  ${media.lessThan('medium')`
    padding: 16px;
  `}
`

export const ContentTop = styled.div`
  margin-bottom: 45px;

  display: flex;
  align-items: center;
`

export const ContentBottom = styled.div`
  margin-top: 3.2rem;

  display: flex;
  flex-direction: row;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const WrapperLeft = styled.div`
  ${({ theme }) => css`
    max-width: 751px;
    width: 100%;

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.6rem;

      color: ${theme.colors.maida.primary.default};

      margin-bottom: 0.8rem;
    }

    p {
      padding: 0;
      font-size: 1.6rem;
      line-height: 2.4rem;

      text-align: justify;

      margin-top: 1.2rem;
    }

    li {
      padding: 0;
      font-size: 1.6rem;
      line-height: 2.4rem;

      text-align: justify;

      margin-top: 1.2rem;
      margin-left: 1.6rem;
    }
  `}
`

export const WrapperRight = styled.div`
  max-width: 475px;
  width: 100%;

  margin-left: 6rem;

  ${media.lessThan('medium')`
    margin-left: 0;

    margin-top: 1.6rem;
    max-width: 100%;
  `}
`

export const WrapperBTNMore = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;

  margin-top: 2rem;

  button {
    max-width: 450px;
    width: 100%;
  }
`
