import React from 'react'
import * as S from './styles'

export type DrawerProps = {
  children: React.ReactNode
  isOpen: boolean
  onRequestClose: () => void
}

const Drawer = ({ children, isOpen, onRequestClose }: DrawerProps) => {
  const drawerContent = React.useRef<any>(null)

  const handleClickOutside = React.useCallback((event: any) => {
    if (
      drawerContent.current &&
      !drawerContent.current?.contains(event.target)
    ) {
      onRequestClose()
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [drawerContent])

  return (
    <S.Wrapper isOpen={isOpen}>
      <S.Content ref={drawerContent}>{children}</S.Content>
    </S.Wrapper>
  )
}

export default Drawer
