import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;

  position: relative;
`

export const WrapperDropdown = styled.div`
  position: absolute;
  top: 6.4rem;

  z-index: 15;

  width: 100%;
`
type DropdownProps = {
  isOpen: boolean
}

export const Dropdown = styled.div<DropdownProps>`
  ${({ theme, isOpen }) => css`
    max-height: 18.8rem;
    width: 100%;

    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    border-radius: ${theme.border.radius};

    overflow-y: auto;
    overflow-x: hidden;

    background: ${theme.colors.white[100]};

    padding: 0.8rem 0;

    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: flex-start;

    padding: 1.6rem 1.8rem;
  `}
`

export const WrapperLoading = styled.div``
