import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  width: 100vw;

  background: #fff;

  z-index: 50;

  padding: 1.6rem;

  overflow: auto;
`

export const ContainerTop = styled.div``

export const WrapperButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-top: 2.4rem;

    div.wrapper__button__view {
      width: 21.3rem;

      button {
        width: 100%;
      }

      &:nth-child(1) {
        .maida-button--wrapper {
          border-color: rgba(0, 0, 0, 0.56);

          &:hover {
            background: #fff;
          }
        }

        .maida-button--text {
          color: rgba(0, 0, 0, 0.56);
        }
      }
    }

    > div.wrapper__button__view + div.wrapper__button__view {
      margin-left: 1rem;
    }

    ${media.lessThan('medium')`
      flex-direction: column;

      div.wrapper__button__view {
        max-width: 20rem;

        button {
          width: 100%;
        }

        &:nth-child(2) {
          order: -1;
          margin-bottom: 1rem;
        }
      }

      > div.wrapper__button__view + div.wrapper__button__view {
        margin-left: 0;

      }
    `}
  `}
`
