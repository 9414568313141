import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  div.content--iconBack {
    flex: 1;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
`

export const WrapperArrowBack = styled(Link)`
  ${({ theme }) => css`
    display: inline-block;
    text-decoration: none;

    margin-bottom: 1.8rem;

    max-width: 30px;
    max-height: 30px;

    svg path {
      fill: ${theme.colors.maida.primary.default};
    }
  `}
`
