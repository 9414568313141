import Illustration from 'assets/illustrations/homepage-container-main.svg'

import * as S from './styles'

export function ContainerMain() {
  return (
    <S.Wrapper>
      <S.ContentLeft>
        <h3>Olá, seja bem vindo!</h3>
        <p>
          Aqui você encontra todas as Diretrizes de Regulações cadastradas, é
          possível pesquisar no campo de busca ou filtar por cliente.{' '}
        </p>
      </S.ContentLeft>

      <S.ContentRight>
        <img src={Illustration} alt="" />
      </S.ContentRight>
    </S.Wrapper>
  )
}
