import Joi from 'joi'

export type FieldsProfileEditData = {
  name: string
  newPassword: string
  confirmationPassword: string
}

export type FieldErrors = {
  [key: string]: string
}

const fieldsValidations = {
  name: Joi.string().required(),
  newPassword: Joi.any(),
  confirmationPassword: Joi.any()
    .valid(Joi.ref('newPassword'))
    .messages({ 'any.only': 'Senha não são iguais.' })
}

function getFieldErrors(objError: Joi.ValidationResult) {
  const errors: FieldErrors = {}

  if (objError.error) {
    objError.error.details.forEach((err) => {
      errors[err.path.join('.')] = err.message
    })
  }

  return errors
}

export function onRequestEditProfile(values: FieldsProfileEditData) {
  const schema = Joi.object(fieldsValidations)

  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}
