import React from 'react'
import { Checkbox } from 'maida-ui'

import Input from 'components/atoms/Input'

import IconDown from 'assets/imgs/mai-ic-chevron-single-down.svg'
import IconUp from 'assets/imgs/mai-ic-chevron-single-up.svg'

import * as S from './styles'

type SelectedData = {
  value: string
  label: string
}

type ReturnValues = {
  valueView: string // valor para ViewContainer
  checkedSeleted: number[] // valor montado para Request
}

type SelectCheckboxProps = {
  placeholder: string
  dataDropdown: SelectedData[] // dados do checkbox
  isLoading: boolean
  name: string
  handleSelectedChange?: (val: ReturnValues) => void // captura as mudanca do checkbox
  initialValue?: string[] | number[]
}

export const SelectCheckbox = ({
  placeholder,
  dataDropdown,
  isLoading,
  name,
  handleSelectedChange,
  initialValue = []
}: SelectCheckboxProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  // STATE usado para montar o valor do ViewContainer
  const [value, setValue] = React.useState<string>('')

  // STATE usado para montar o array a ser enviado na request
  const [arrayValues, setArrayValue] = React.useState<string[]>([])

  const [idChecked, setIdChecked] = React.useState<number[]>([])

  // STATE para Controlled Component de Checkbox (quais estao marcados)
  const [checked, setChecked] = React.useState<string[]>()
  const [isCheckeds, setIsCheckeds] = React.useState<SelectedData[]>([])
  const refElement = React.useRef<any>(null)

  const [isUpdate, setIsUpdate] = React.useState(false)

  function handleClickOutside(event: any) {
    if (refElement.current && !refElement.current?.contains(event.target)) {
      setIsOpen(false)
    }
  }

  function onClickIconRight() {
    setIsOpen(!isOpen)
  }

  function onSelectedAll(): number[] | any {
    const filter = dataDropdown.filter((option) => {
      if (Number(option.value) !== 0) {
        return option
      }
    })

    const formatToNumber = filter.map((val) => Number(val.value))

    return formatToNumber
  }

  function groupValuesSelectedToViewInput(option: SelectedData) {
    let values = [...arrayValues]
    let valuesChecked = [...idChecked]

    if (option.value === '0' && values.indexOf('Todos') === -1) {
      setChecked(['0'])
      valuesChecked = []

      valuesChecked = onSelectedAll()
      values = ['Todos']
    } else {
      if (values.indexOf('Todos') > -1) {
        values = []
        valuesChecked = []
        setChecked([])
      }

      if (value.length > 0 && value.indexOf(option.label) === -1) {
        values = [...values, option.label]
        valuesChecked = [...valuesChecked, Number(option.value)]
      } else if (value.length > 0 && value.indexOf(option.label) !== -1) {
        const filter = values.filter((value) => value !== option.label)
        const filterTwo = valuesChecked.filter(
          (value) => value !== Number(option.value)
        )

        values = filter
        valuesChecked = filterTwo
      } else {
        values = [option.label]
        valuesChecked = [Number(option.value)]
      }

      const formatChecked = valuesChecked.map((check: number) => {
        return String(check)
      })

      setChecked(formatChecked)
    }

    setArrayValue(values)
    setValue(values.join(', '))
    setIdChecked(valuesChecked)

    !!handleSelectedChange &&
      handleSelectedChange({
        checkedSeleted: valuesChecked,
        valueView: values.join(', ')
      })
  }

  function onSelectedItem(value: SelectedData) {
    groupValuesSelectedToViewInput(value)

    setIsCheckeds((oldState) => [...oldState, value])
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refElement])

  React.useEffect(() => {
    if (!!initialValue.length && !isUpdate) {
      // const filter = dataDropdown.filter(
      //   (opt) => {
      //     return
      //   }
      // )

      const filter = initialValue.map((event: any) => {
        const filter = dataDropdown?.find((opt: any) => {
          return String(opt.value) === String(event)
        })

        return filter
      })

      console.log('filter', filter)

      const formatView = filter.map((opt: any) => {
        return opt.label
      })
      const formatCheck = filter.map((opt: any) => {
        return opt.value
      })

      setChecked(formatCheck)

      setValue(formatView.join(', '))

      setArrayValue([String(formatView)])
      setIdChecked([Number(initialValue)])

      setTimeout(() => {
        setIsUpdate(true)
      }, 400)
    }
  }, [initialValue])

  return (
    <S.Wrapper ref={refElement}>
      <Input
        isDefault="small"
        name={name}
        iconRight={isOpen ? IconUp : IconDown}
        onClickIconRight={onClickIconRight}
        isSelect
        placeholder={placeholder}
        initialValue={value}
      />

      <S.WrapperDropdown>
        <S.Dropdown isOpen={isOpen}>
          {isLoading ? (
            <S.WrapperLoading>Carregando...</S.WrapperLoading>
          ) : (
            <Checkbox
              options={dataDropdown}
              valueModifiers={checked}
              onCheck={(option) => onSelectedItem(option)}
              name={name}
            />
          )}
        </S.Dropdown>
      </S.WrapperDropdown>
    </S.Wrapper>
  )
}
