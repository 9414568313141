import parse from 'html-react-parser'

import * as S from './styles'

export type ViewDetailsCreateProps = {
  procedureCode: string
  procedureName: string
  events: string
  clients: string
  description: string
}

export const ViewDetails = ({
  procedureCode,
  procedureName,
  events,
  clients,
  description
}: ViewDetailsCreateProps) => {
  return (
    <>
      <S.PreView>
        <p>Pré-visualização</p>
      </S.PreView>
      <S.Wrapper>
        <S.Item>
          <p className="title">CÓDIGO DO PROCEDIMENTO</p>
          <p>{procedureCode ? procedureCode : '...'}</p>
        </S.Item>
        <S.Item>
          <p className="title">NOME DO PROCEDIMENTO</p>
          <p>{procedureName ? procedureName : '...'}</p>
        </S.Item>
        <S.Item>
          <p className="title">EVENTO DE ATUAÇÃO</p>
          <p>{events ? events : '...'}</p>
        </S.Item>
        <S.Item>
          <p className="title">CLIENTE VINCULADO A ESSA DIRETRIZ</p>
          <p>{clients ? clients : '...'}</p>
        </S.Item>
        <S.Item>
          <p className="title">DIRETRIZ</p>
          <S.WrapperDraftView>
            {description ? parse(description) : '...'}
          </S.WrapperDraftView>
        </S.Item>
      </S.Wrapper>
    </>
  )
}
