import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import parse from 'html-react-parser'

import HeadingArrowBack from 'components/atoms/HeadingArrowBack'
import { ViewDetails } from './ViewDetails'
import { Button } from 'maida-ui'

import * as S from './styles'
import { GuidelineData } from 'services/guidelines'

export const Guideline = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const { state: guideline } = useLocation<GuidelineData>()

  const [showMoreInfo, setShowMoreInfo] = React.useState(false)

  return (
    <S.Wrapper>
      <S.Content>
        <S.ContentTop>
          <div className="back">
            <HeadingArrowBack
              label="Diretrizes para regulação"
              to="/homepage"
            />
          </div>
        </S.ContentTop>

        <S.ContentBottom>
          <S.WrapperLeft>
            <h4>DIRETRIZ</h4>
            {parse(guideline.description)}
          </S.WrapperLeft>
          {isMobile && !showMoreInfo && (
            <S.WrapperBTNMore>
              <Button
                type="button"
                typeButton="ghost"
                onClick={() => setShowMoreInfo(true)}
              >
                VISUALIZAR MAIS INFORMAÇÕES
              </Button>
            </S.WrapperBTNMore>
          )}
          <S.WrapperRight>
            {(!isMobile || showMoreInfo) && (
              <ViewDetails
                procedureCode={String(guideline.procedure_code)}
                procedureName={guideline.procedure_name}
                events={guideline.event_ids}
                clients={guideline.client_ids}
              />
            )}
          </S.WrapperRight>
        </S.ContentBottom>
      </S.Content>
    </S.Wrapper>
  )
}
