/* eslint-disable @typescript-eslint/no-empty-function */
import { InputHTMLAttributes, useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'

import isVisibledImg from 'assets/imgs/mai-ic-visible-true.svg'
import isVisibledFalseImg from 'assets/imgs/mai-ic-visible-false.svg'

import * as S from './styles'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
import GlobalStyles from 'styles/global'

export type isDefaultType = 'default' | 'small'
export type StateTypes = 'default' | 'danger' | 'success' | 'octopus'
export type MasksTypes =
  | 'cep'
  | 'cnpj'
  | 'cpf'
  | 'phone'
  | 'date'
  | 'time'
  | 'card'
  | 'monetary'
  | ''

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  assistiveText?: string
  disabled?: boolean
  iconLeft?: string
  isDefault: isDefaultType
  mask?: MasksTypes
  label?: string
  state?: StateTypes
  type?: 'number' | 'text' | 'password'
  iconPassword?: boolean
  value?: string
  initialValue?: string
  handleOnChange?: (value: any) => any
  handleOnFocus?: () => any
  handleOnBlur?: () => any
  iconRight?: string
  onClickIconRight?: () => void
  isSelect?: boolean
}

const Input = ({
  label = 'placeholder',
  assistiveText,
  isDefault = 'default',
  type = 'text',
  mask,
  iconLeft = '',
  state = 'default',
  iconPassword = false,
  value = '',
  handleOnChange,
  handleOnBlur,
  handleOnFocus,
  iconRight = '',
  onClickIconRight,
  isSelect,
  disabled = false,
  initialValue,
  ...rest
}: InputProps) => {
  const [valueState, setValue] = useState(value)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [typeState, setType] = useState(type)
  const [isFocus, setIsFocus] = useState(false)
  const [isFill, setIsFill] = useState(false)

  const changeInputValue = (event: any) => {
    !!handleOnBlur && handleOnChange!(event)

    return setValue(event.target.value)
  }

  function onFocus() {
    setIsFocus(true)

    !!handleOnFocus && handleOnFocus()
  }

  function onBlur() {
    setIsFocus(false)
    setIsFill(!!valueState)

    !!handleOnBlur && handleOnBlur()
  }

  function onClickVisibilit() {
    const val = !isShowPassword

    setIsShowPassword(val)

    if (val) return setType('text')

    return setType('password')
  }

  function clickedIconRight() {
    !!onClickIconRight && onClickIconRight()
  }

  useEffect(() => {
    if (iconPassword) {
      setType('password')
    } else {
      setType('text')
    }
  }, [iconPassword])

  useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <>
      <S.Wrapper
        isFocus={isFocus}
        isFill={isFill}
        isIconLeft={!!iconLeft}
        state={state}
        onClick={isSelect ? clickedIconRight : () => {}}
      >
        <S.WrapperLeft>
          {!!iconLeft && (
            <S.IconLeft>
              <ReactSVG src={iconLeft} wrapper="span" />
            </S.IconLeft>
          )}
          <S.Input
            value={valueState}
            placeholder={isDefault === 'small' ? label : ''}
            onChange={(e) => changeInputValue(e)}
            onFocus={onFocus}
            onBlur={onBlur}
            type={typeState}
            disabled={isSelect || disabled}
            {...rest}
          />
        </S.WrapperLeft>
        {isDefault === 'default' && <S.Label>{label}</S.Label>}
        {iconPassword && (
          <S.ButtonPassword state={state} onClick={onClickVisibilit}>
            {isShowPassword ? (
              <ReactSVG src={isVisibledFalseImg} wrapper="span" />
            ) : (
              <ReactSVG src={isVisibledImg} wrapper="span" />
            )}
          </S.ButtonPassword>
        )}
        {!!iconRight && (
          <S.ButtonIconRight
            type="button"
            state={state}
            onClick={clickedIconRight}
          >
            <ReactSVG src={iconRight} wrapper="span" />
          </S.ButtonIconRight>
        )}
      </S.Wrapper>
      {!!assistiveText && (
        <S.ContentAssistiveText state={state}>
          {assistiveText}
        </S.ContentAssistiveText>
      )}
    </>
  )
}

export default Input
