import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const WrapperIllustration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WrapperForm = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxlarge};

    width: 100%;

    div.wrapper-form--text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p,
      h2 {
        text-align: center;
      }

      p {
        color: ${theme.colors.black[56]};
      }
    }

    div.wrapper-form--forms {
      margin-top: ${theme.spacings.xxlarge};

      div.wrapper-form--forms--input {
        margin-top: 2.4rem;

        display: flex;
        flex-direction: column;

        &:first-child {
          margin-top: 0;
        }
      }

      div {
        display: flex;
        flex-direction: row;
      }
    }

    div.wrapper-form--divider {
      span {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 600;

        color: ${theme.colors.black[56]};

        position: relative;
        display: inline-block;

        width: 100%;

        text-align: center;

        margin: 3rem 0;

        &::before {
          content: '';
          width: 45%;
          height: 1px;
          background: ${theme.colors.black[40]};
          position: absolute;
          left: 0;
          bottom: 7px;
        }

        &::after {
          content: '';
          width: 45%;
          height: 1px;
          background: ${theme.colors.black[40]};
          position: absolute;
          right: 0;
          bottom: 7px;
        }
      }
    }

    ${media.greaterThan('medium')`
      max-width: 37.6rem;

      margin-top: auto;

      div.wrapper-form--text {
        h2 {
          font-size: 2.4rem;
          line-height: 3.6rem;
        }
      }
    `}
  `}
`

export const WrapperFooter = styled.form`
  ${({ theme }) => css`
    p {
      padding: 0;

      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 600;

      text-align: center;

      color: ${theme.colors.black[56]};

      margin-top: 8rem;

      span {
        color: ${theme.colors.maida.primary.default};
      }
    }

    ${media.greaterThan('medium')`
      margin-top: auto;
    `}
  `}
`

export const WrapperButton = styled.div`
  display: flex;

  > button {
    width: 100%;
    text-transform: uppercase;
  }
`

export const WrapperForgotPassword = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 5.4rem;
`

export const WrapperSignUp = styled.div`
  ${({ theme }) => css`
    margin-top: 2.8rem;

    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: ${theme.colors.black[56]};

      a {
        color: ${theme.colors.maida.primary.default};
        text-decoration: none;
      }
    }
  `}
`
