import HeadingArrowBack from 'components/atoms/HeadingArrowBack'
import { Button } from 'maida-ui'
import { ViewDetails, ViewDetailsCreateProps } from '../ViewDetails'

import * as S from './styles'

type ModalViewDetailsProps = {
  valuesViewContainer: ViewDetailsCreateProps
  onRequestClose: () => void
  loading: boolean
  isActived: boolean
  onSubmit: () => void
}

export const ModalViewDetails = ({
  valuesViewContainer,
  onRequestClose,
  loading,
  isActived,
  onSubmit
}: ModalViewDetailsProps) => {
  return (
    <S.Wrapper>
      <S.ContainerTop>
        <div onClick={onRequestClose}>
          <HeadingArrowBack
            label="Visualização antes de salvar"
            to="/diretrizes/criar-nova"
          />
        </div>

        <div className="text">
          <p>Veja no painel abaixo como seu documento será visto.</p>
        </div>
      </S.ContainerTop>

      <ViewDetails {...valuesViewContainer} />

      <S.WrapperButtons>
        <div className="wrapper__button__view">
          <Button typeButton="ghost" onClick={onRequestClose}>
            EDITAR INFORMAÇÕES
          </Button>
        </div>
        <div className="wrapper__button__view">
          <Button
            type="button"
            disabled={loading || isActived}
            onClick={onSubmit}
          >
            {loading ? 'ENVIANDO...' : 'SALVAR DESTA FORMA'}
          </Button>
        </div>
      </S.WrapperButtons>
    </S.Wrapper>
  )
}
