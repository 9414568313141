import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Input, Button } from 'maida-ui'
import { useForm } from 'react-hook-form'

import ImgMobile from 'assets/illustrations/sign-in-m.svg'
import IconMail from 'assets/img/mai-ic-email.mono.svg'
import IconBTNOctopus from 'assets/img/oct-ic.svg'
import IconPassword from 'assets/img/mai-ic-password.mono.svg'

import * as S from './styles'
import { ReactSVG } from 'react-svg'
import { ModalSignInOct } from './ModalLoginOctopus'
import { PrimaryTemplate } from 'components/templates/PrimaryTemplate'
import { validateEmail } from 'utils/validationEmails'
import { useAuth } from 'hooks/auth'
import { sign } from 'crypto'
import { queryClient } from 'services/queryClient'

const initialValues = {
  email: '',
  password: ''
}

export const SignIn: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const navigation = useHistory()
  const { signIn } = useAuth()
  const { handleSubmit, formState } = useForm()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErros] = React.useState(initialValues)
  const [showModalOct, setShowModalOct] = React.useState(false)
  const [activedButton, setActivedButton] = React.useState(false)

  function handleInputEmail(value: string): void {
    setEmail(value)
    setErros({
      ...errors,
      email: ''
    })

    if (value.length > 0) {
      const error = validateEmail(value)

      setErros({
        ...errors,
        email: String(error) || ''
      })
    }
  }

  function handleInputPassword(value: string) {
    setPassword(value)
    setErros({
      ...errors,
      password: ''
    })

    if (value.length > 0) {
      setErros({
        ...errors,
        password: 'Senha deve ter no mínimo 6 caracteres '
      })

      if (value.length > 5) {
        setErros({
          ...errors,
          password: ''
        })
      }
    }
  }

  const onSignIn = async () => {
    try {
      await signIn.mutateAsync({ password, email })

      setTimeout(() => {
        navigation.push('/homepage')
      }, 3000)
    } catch (err) {
      if (err.response.status === 401) {
        setErros({
          ...errors,
          email: 'Verifique seu email!',
          password: 'Verifique sua senha!'
        })
      }
    }
  }

  React.useEffect(() => {
    const rule1 = !password || !email
    const rule2 = !!errors.email || !!errors.password

    setActivedButton(rule1 || rule2)
  }, [errors, email, password])

  React.useEffect(() => {
    queryClient.removeQueries('guidelines')
  }, [])

  return (
    <PrimaryTemplate>
      {isMobile && (
        <S.WrapperIllustration>
          <ReactSVG src={ImgMobile} wrapper="span" />
        </S.WrapperIllustration>
      )}

      <S.WrapperForm>
        <div className="wrapper-form--text">
          <h2>Bem-vindo ao MedicalBook </h2>
          <p>Preencha o campo abaixo para receber o acesso ao sistema.</p>
        </div>

        <div className="wrapper-form--forms">
          <div className="wrapper-form--forms--input">
            <Input
              iconLeft={IconMail}
              isDefault="default"
              label="e-mail"
              value={email}
              assistiveText={errors.email ? errors.email : ''}
              state={errors.email ? 'danger' : 'default'}
              handleOnChange={(value: string) => handleInputEmail(value)}
            />
          </div>

          <div className="wrapper-form--forms--input">
            <Input
              iconLeft={IconPassword}
              isDefault="default"
              label="senha"
              value={password}
              type="password"
              iconPassword
              assistiveText={errors.password ? errors.password : ''}
              state={errors.password ? 'danger' : 'default'}
              handleOnChange={(value: string) => handleInputPassword(value)}
            />
          </div>

          <S.WrapperForgotPassword>
            <Button
              type="button"
              typeButton="text"
              onClick={() => navigation.push('/recuperar-senha')}
            >
              ESQUECI MINHA SENHA
            </Button>
          </S.WrapperForgotPassword>

          <S.WrapperButton style={{ marginTop: 24 }}>
            <Button
              onClick={handleSubmit(onSignIn)}
              disabled={activedButton || formState.isSubmitting}
            >
              {formState.isSubmitting ? 'Carregando...' : 'Entrar'}
            </Button>
          </S.WrapperButton>

          <div className="wrapper-form--divider">
            <span>ou</span>
          </div>

          <S.WrapperButton>
            <Button
              iconLeft={IconBTNOctopus}
              themeButton="octopus"
              onClick={() => setShowModalOct(!showModalOct)}
            >
              Fazer login com o octopus
            </Button>

            {/* MODAL */}

            <ModalSignInOct
              isOpen={showModalOct}
              onRequestClose={() => setShowModalOct(!showModalOct)}
            />
          </S.WrapperButton>
        </div>
      </S.WrapperForm>

      <S.WrapperSignUp>
        <h3>
          Ainda não é cadastrado? <Link to="/criar-conta">Criar conta</Link>
        </h3>
      </S.WrapperSignUp>

      <S.WrapperFooter>
        <p>
          Produto <span>maida.health</span>.
        </p>
      </S.WrapperFooter>
    </PrimaryTemplate>
  )
}
