import { ReactSVG } from 'react-svg'
import { Input, Button, useToast } from 'maida-ui'

import Drawer from 'components/Drawer'

import IconClose from 'assets/imgs/mai-ic-close.svg'
import {
  FieldErrors,
  FieldsProfileEditData,
  onRequestEditProfile
} from './validation'

import * as S from './styles'
import { useAuth, UserData } from 'hooks/auth'
import React from 'react'
import { apiAuth } from 'services/apis/apiAuth'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
}

export const DrawerProfile = ({ isOpen, onRequestClose }: Props) => {
  const { userData, editPassword, userEditData } = useAuth()
  const { addToast } = useToast()
  const [loading, setLoading] = React.useState(false)
  const [fieldsValues, setFieldsValues] = React.useState<FieldsProfileEditData>(
    { name: '', newPassword: '', confirmationPassword: '' }
  )
  const [fieldsErrors, setFieldsErrors] = React.useState<FieldErrors>({})

  function onChange(value: string, key: string) {
    setFieldsValues((oldState) => ({ ...oldState, [key]: value }))
  }

  const onRequestEditDataUser = () => {
    const userData: any = localStorage.getItem('@MEDICALBOOK-OCT:USER-DATA')
    const formatedData: any = JSON.parse(userData)

    const newData = {
      ...formatedData,
      name: fieldsValues.name
    }

    localStorage.setItem('@MEDICALBOOK-OCT:USER-DATA', JSON.parse(newData))

    addToast({
      title: 'Dados alterados com sucesso.'
    })
  }

  async function onRequest(event: React.FormEvent) {
    event.preventDefault()

    // validacao dos campos
    const errors = onRequestEditProfile(fieldsValues)

    if (Object.keys(errors).length) {
      setFieldsErrors(errors)

      return
    }

    setFieldsErrors({})

    setLoading(true)

    if (fieldsValues.newPassword) {
      apiAuth
        .put('/auth/password', {
          password: fieldsValues.newPassword,
          password_confirmation: fieldsValues.confirmationPassword
        })
        .then(() => {
          setFieldsValues((oldState) => ({
            ...oldState,
            newPassword: '',
            confirmationPassword: ''
          }))
        })
        .catch(() => {
          addToast({
            title: 'Erro ao alterar as senha, tente novamente em instantes!',
            type: 'error'
          })
        })
    }

    // logica para request
    apiAuth
      .put('/users/current', { name: fieldsValues.name })
      .then(() => onRequestEditDataUser())
      .catch(() => {
        addToast({
          title: 'Erro ao alterar o nome, tente novamente em instantes!',
          type: 'error'
        })
      })

    setLoading(false)
  }

  React.useEffect(() => {
    setFieldsValues({
      ...fieldsValues,
      name: userData.name
    })
  }, [])

  return (
    <Drawer isOpen={isOpen} onRequestClose={onRequestClose}>
      <S.Wrapper>
        <S.Header>
          <button
            type="button"
            className="header__button"
            onClick={onRequestClose}
          >
            <ReactSVG src={IconClose} />
          </button>

          <h2>Editar meus dados</h2>
        </S.Header>
        <S.Content onSubmit={onRequest}>
          <S.WrapperContent>
            <h2>Dados pessoais</h2>

            <div className="wrapperContent__inputGroup">
              <label htmlFor="name">Nome completo</label>
              <Input
                isDefault="small"
                name="name"
                id="name"
                placeholder="Insira aqui seu nome"
                initialValue={fieldsValues.name}
                handleOnChange={(value) => onChange(value, 'name')}
              />
            </div>
          </S.WrapperContent>

          <S.WrapperContent>
            <h2>Acesso à conta</h2>

            <div className="wrapperContent__inputGroup">
              <label htmlFor="newPassword">Nova senha</label>
              <Input
                isDefault="small"
                placeholder="Insira aqui a nova senha"
                name="newPassword"
                id="newPassword"
                handleOnChange={(value) => onChange(value, 'newPassword')}
                state={
                  fieldsErrors?.confirmationPassword ? 'danger' : 'default'
                }
                initialValue={fieldsValues.newPassword}
                assistiveText={fieldsErrors?.confirmationPassword}
                iconPassword
              />
            </div>

            <div className="wrapperContent__inputGroup">
              <label htmlFor="confirmationPassword">Confirmar senha</label>
              <Input
                isDefault="small"
                name="confirmationPassword"
                id="confirmationPassword"
                placeholder="Insira aqui a nova senha"
                handleOnChange={(value) =>
                  onChange(value, 'confirmationPassword')
                }
                state={
                  fieldsErrors?.confirmationPassword ? 'danger' : 'default'
                }
                initialValue={fieldsValues.confirmationPassword}
                assistiveText={fieldsErrors?.confirmationPassword}
                iconPassword
              />
            </div>
          </S.WrapperContent>

          <S.WrapperButton>
            <div className="wrapperButton__btn">
              <Button>SALVAR</Button>
            </div>
          </S.WrapperButton>
        </S.Content>
      </S.Wrapper>
    </Drawer>
  )
}
