import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import Bg from 'assets/illustrations/sign-in-bg.svg'

export const Wrapper = styled.div`
  background: #fafafa;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${media.lessThan('medium')`
  display: flex;
`}
`

export const WrapperIllustration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;

  max-width: 47.8rem;
  width: 100%;
  min-height: 100vh;

  padding: 1.6rem;

  margin: 0 auto;

  ${media.greaterThan('medium')`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`

export const ContentLeft = styled.div`
  min-height: 100vh;
  width: 100%;

  padding: 5.6rem;

  background: url(${Bg}) #fff no-repeat bottom center;
  background-size: contain;
`
