import styled, { keyframes, css } from 'styled-components'
import { DrawerProps } from '.'

// animacoes
const fromRight = keyframes`
  from { transform: translateX(100%) }
  to { transform: translateX(0) }
`

export const Wrapper = styled.div<Pick<DrawerProps, 'isOpen'>>`
  ${({ isOpen }) => css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.4);

    display: ${isOpen ? 'flex' : 'none'};

    z-index: 40;
  `}
`

export const Content = styled.div`
  position: absolute;
  right: 0;
  width: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;

  background: #fff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);

  right: 0px 0px 10px rgb(0 0 0 / 10%);
  animation: 0.25s ease-out 0s 1 normal none running ${fromRight};
`
