import { useAuth } from 'hooks/auth'
import { GuidelineCreate } from 'pages/CreateGuideline'
import { ForgotPassword } from 'pages/ForgotPassword'
import { Guideline } from 'pages/Guideline'
import { Homepage } from 'pages/Homepage'
import { ResetPassword } from 'pages/ResetPassword'
import { SignIn } from 'pages/SignIn'
import { SignUp } from 'pages/SignUp'
import { Switch } from 'react-router-dom'
import { RoutePrivate } from './guards/RoutePrivate'
import { RouteVisitant } from './guards/RouteVisitant'

export const Routes = () => {
  const { userData } = useAuth()

  return (
    <Switch>
      <RouteVisitant exact path="/" component={SignIn} />
      <RouteVisitant path="/criar-conta" component={SignUp} />
      <RouteVisitant path="/recuperar-senha" component={ForgotPassword} />
      <RouteVisitant path="/nova-senha" component={ResetPassword} />
      <RoutePrivate path="/homepage" component={Homepage} />

      {userData?.profile !== undefined && userData.profile === 'customer' && (
        <RoutePrivate exact path="/diretriz/:id" component={Guideline} />
      )}

      {userData?.profile !== undefined && userData.profile !== 'customer' && (
        <>
          <RoutePrivate
            exact
            path="/diretrizes/criar-nova"
            component={GuidelineCreate}
          />
          <RoutePrivate
            exact
            path="/diretrizes/editar/:id"
            component={GuidelineCreate}
          />
        </>
      )}
    </Switch>
  )
}
