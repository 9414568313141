import styled, { css } from 'styled-components'
import { LoadingProps } from '.'

const modifiers = {
  default: () => css`
    width: 17rem;
    height: 17rem;
  `,
  small: () => css`
    width: 9.8rem;
    height: 9.8rem;
  `
}

export const Wrapper = styled.div<LoadingProps>`
  ${({ size }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    ${modifiers[size!]}
  `}
`
