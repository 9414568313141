import { Button } from 'maida-ui'
import { useHistory } from 'react-router-dom'

import Illustration from 'assets/illustrations/homepage-container-main.svg'

import * as S from './styles'
import { useAuth } from 'hooks/auth'

export function ContainerMainCreate() {
  const navigation = useHistory()
  const { userData } = useAuth()

  return (
    <S.Wrapper>
      <S.ContentLeft>
        <h3>Olá, {userData.name}!</h3>
        <p>
          Aqui você encontra todas as Diretrizes de Regulações cadastradas, é
          possível pesquisar no campo de busca, filtar por cliente e cadastras
          novas diretrizes.
        </p>
        <div className="wrapper__button">
          <Button onClick={() => navigation.push('/diretrizes/criar-nova')}>
            CADASTRAR NOVA DIRETRIZ
          </Button>
        </div>
      </S.ContentLeft>

      <S.ContentRight>
        <img src={Illustration} alt="" />
      </S.ContentRight>
    </S.Wrapper>
  )
}
