import { Header } from 'maida-ui'
import { ReactSVG } from 'react-svg'

import IconArrowBack from 'assets/imgs/mai-ic-arrow-back.svg'

import * as S from './styles'

interface HeadingArrowBackProps {
  label: string
  to: string
}

function HeadingArrowBack({ label, to }: HeadingArrowBackProps) {
  return (
    <S.Wrapper>
      <div className="content--iconBack">
        <S.WrapperArrowBack to={to}>
          <ReactSVG src={IconArrowBack} wrapper="span" />
        </S.WrapperArrowBack>

        <Header label={label} />
      </div>
    </S.Wrapper>
  )
}

export default HeadingArrowBack
