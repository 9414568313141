import styled, { css } from 'styled-components'

export const Button = styled.button`
  ${({ theme }) => css`
    width: 30px;

    border: none;
    background: transparent;

    cursor: pointer;

    svg path {
      fill: ${theme.colors.maida.primary.default};
    }
  `}
`

export const Wrapper = styled.div`
  position: relative;
`

export const Content = styled.div`
  position: absolute;

  right: 10px;

  z-index: 20;
`
