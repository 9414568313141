import React from 'react'
import { Checkbox } from 'maida-ui'
import { ReactSVG } from 'react-svg'

import IconBottom from 'assets/imgs/mai-ic-chevron-single-down.svg'
import IconTop from 'assets/imgs/mai-ic-chevron-single-up.svg'

import * as S from './styles'
import { useClientService } from 'services/clients'
import Loading from 'components/Loading'

type ClientsData = {
  value: string
  label: string
}

type FiltersProps = {
  setSelectedClients: (options: ClientsData) => void
}

export const Filter = ({ setSelectedClients }: FiltersProps) => {
  const [show, setShow] = React.useState(false)
  const [clients, setClients] = React.useState<ClientsData[]>([])
  const [filters, setFilters] = React.useState<number[]>([])
  const [checks, setChecks] = React.useState<string[]>(['0'])
  const [totalFilters, setTotalFilters] = React.useState(0)
  const refElement = React.useRef<any>(null)

  const { isLoading, data, error } = useClientService()

  function handleClickOutside(event: any) {
    if (refElement.current && !refElement.current?.contains(event.target)) {
      setShow(false)
    }
  }

  const onSelectedAll = () => {
    setFilters([])
    setChecks(['0'])
  }

  const onSelectedOption = (value: number) => {
    if (filters.indexOf(value) === -1) {
      setFilters((oldState) => [...oldState, value])
    } else {
      const filter = filters.filter((op) => op !== value)
      setFilters(filter)
    }
  }

  const onRemoveAllWhenSelectedOption = (value: string) => {
    if (value === '0') {
      setFilters([])
      setChecks([])
    }
  }

  const onCheckboxChange = (option: ClientsData) => {
    if (option.value !== '0') {
      onRemoveAllWhenSelectedOption(option.value)
      onSelectedOption(Number(option.value))
    } else {
      onSelectedAll()
    }

    setSelectedClients(option)
  }

  React.useEffect(() => {
    setTotalFilters(filters.length)

    const filtersParseString = filters.map((filter) => String(filter))
    setChecks(filtersParseString)
  }, [filters])

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refElement])

  React.useEffect(() => {
    if (data?.length > 0) {
      let filters = [{ value: '0', label: 'Todos' }]

      const dataFormat = data.map((client: any) => {
        return {
          value: String(client.id),
          label: client.company
        }
      })

      filters = [...filters, ...dataFormat]

      setClients(filters)
    }
  }, [data])

  return (
    <S.Wrapper>
      <S.Container>
        <p>Filtrar</p>

        <S.WrapperCounter>
          <span>{totalFilters}</span>
        </S.WrapperCounter>

        <S.BtnArrow type="button" onClick={() => setShow(!show)}>
          <ReactSVG src={show ? IconTop : IconBottom} wrapper="span" />
        </S.BtnArrow>
      </S.Container>

      <S.Dropdown ref={refElement} isOpen={show}>
        {isLoading && (
          <S.WrapperLoading>
            <Loading size="small" />
          </S.WrapperLoading>
        )}

        {!isLoading && data?.length > 0 && (
          <Checkbox
            options={clients}
            onCheck={(option: ClientsData) => onCheckboxChange(option)}
            valueModifiers={checks.length ? checks : ['0']}
            name="filter"
          />
        )}
      </S.Dropdown>
    </S.Wrapper>
  )
}
