import { Modal, Button, useToast } from 'maida-ui'
import { useMutation } from 'react-query'
import { GuidelineData, removeGuideline } from 'services/guidelines'
import { queryClient } from 'services/queryClient'

import * as S from './styles'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  idGuidelineDelete: number
}

export const ModalDeleteGuideline = ({
  isOpen,
  onRequestClose,
  idGuidelineDelete
}: Props) => {
  const { mutateAsync } = useMutation(removeGuideline)
  const { addToast } = useToast()

  const onClickedDelete = async (id: number) => {
    await mutateAsync(id, {
      onSuccess: (response) => {
        queryClient.invalidateQueries('guidelines')

        addToast({
          title: 'Diretriz excluida com sucesso',
          type: 'success'
        })

        onRequestClose()
      },
      onError: () => {
        addToast({
          title: 'Acontedeu um erro inesperado! Tente novamente em instantes.',
          type: 'error'
        })
      }
    })
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <S.Container>
        <h2>Deseja excluir esta diretriz?</h2>
        <p>Esta diretriz será excluida permanentemente.</p>

        <S.WrapperButtons>
          <div className="wrapper-btn">
            <Button typeButton="ghost" onClick={onRequestClose}>
              CANCELAR
            </Button>
          </div>
          <div className="wrapper-btn">
            <Button
              themeButton="danger"
              onClick={() => onClickedDelete(idGuidelineDelete)}
            >
              SIM, EXCLUIR
            </Button>
          </div>
        </S.WrapperButtons>
      </S.Container>
    </Modal>
  )
}
