import { useRef, useEffect, useState, memo, useCallback } from 'react'
import { Dropdown } from 'maida-ui'

import IconMore from 'assets/imgs/mai-ic-more.svg'

import * as S from './styles'
import { ReactSVG } from 'react-svg'

export type DataTypeDropdown = {
  label: string
  value: string
  icon?: string
  color?: string
}

export type OverflowProps = {
  clicked?: (option: any) => any
  options: DataTypeDropdown[]
}

function OverflowBase({ options, clicked }: OverflowProps) {
  const [isOpen, setIsOpen] = useState(false)

  const refElement = useRef<any>(null)

  const handleClickOutside = useCallback((event: any) => {
    if (refElement.current && !refElement.current?.contains(event.target)) {
      setIsOpen(false)
    }
  }, [])

  function onSeletedOption(option: any): any {
    setIsOpen(false)

    // retorna o valor selecionado
    !!clicked && clicked(option)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refElement])

  return (
    <S.Wrapper className="maida--ui--overflow">
      <S.Button
        type="button"
        onClick={() => setIsOpen(true)}
        className="maida--ui--overflow__icon"
      >
        <ReactSVG src={IconMore} wrapper="span" />
      </S.Button>
      {isOpen && (
        <S.Content ref={refElement} className="overflow__icon__dropdown">
          <Dropdown
            data={options}
            onSelected={(value: DataTypeDropdown) => onSeletedOption(value)}
          />
        </S.Content>
      )}
    </S.Wrapper>
  )
}

export default memo(OverflowBase, (prevProps, nextProps) => {
  return Object.is(prevProps.options, nextProps.options)
})
