import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  max-width: calc(46.4rem - 4.8rem);

  ${media.lessThan('medium')`
  h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`}
`

export const WrapperFormOCT = styled.form`
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;

  div.wrapper-formoct--inputs {
    width: 100%;

    > div + div {
      margin-top: 2.4rem;
    }

    div {
      display: flex;
      flex-direction: row;
    }
  }

  div.wrapper-formoct--button {
    margin-top: 3.1rem;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end !important;

    margin-left: auto;
  }
`
