import Logo from 'components/Logo'
import React from 'react'

import { useMediaQuery } from 'react-responsive'

import * as S from './styles'

type Props = {
  children: React.ReactNode
}

export const PrimaryTemplate = ({ children }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <S.Wrapper>
      {!isMobile && (
        <S.ContentLeft>
          <Logo />
        </S.ContentLeft>
      )}

      <S.ContentRight>{children}</S.ContentRight>
    </S.Wrapper>
  )
}
