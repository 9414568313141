import React from 'react'
import { useHistory } from 'react-router-dom'
import { Input, Button } from 'maida-ui'
import { useForm } from 'react-hook-form'

import IconMail from 'assets/img/mai-ic-email.mono.svg'
import IconUser from 'assets/img/mai-ic-user.mono.svg'

import IconPassword from 'assets/img/mai-ic-password.mono.svg'

import * as S from './styles'

import { PrimaryTemplate } from 'components/templates/PrimaryTemplate'
import HeadingArrowBack from 'components/atoms/HeadingArrowBack'
import { useEffect } from 'react'
import { validateEmail } from 'utils/validationEmails'
import { useAuth } from 'hooks/auth'

const initialValues = {
  name: '',
  email: '',
  password: '',
  confirmationPassword: ''
}

export const SignUp: React.FC = () => {
  const { handleSubmit, formState } = useForm()
  const { createUserEmail } = useAuth()
  const navigation = useHistory()
  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmationPassword, setConfirmationPassword] = React.useState('')
  const [errors, setErros] = React.useState(initialValues)
  const [activedButton, setActivedButton] = React.useState(false)

  function handleInputName(value: string) {
    setName(value)
    setErros({
      ...errors,
      name: ''
    })
  }

  function handleInputEmail(value: string): void {
    setEmail(value)
    setErros({
      ...errors,
      email: ''
    })

    if (value.length > 0) {
      const error = validateEmail(value)

      setErros({
        ...errors,
        email: String(error) || ''
      })
    }
  }

  function handleInputPassword(value: string) {
    setPassword(value)
    setErros({
      ...errors,
      password: ''
    })

    if (value.length > 0) {
      setErros({
        ...errors,
        password: 'Senha deve ter no mínimo 6 caracteres '
      })

      if (value.length > 5) {
        setErros({
          ...errors,
          password: ''
        })
      }
    }
  }

  function handleInputConfirmationPassword(value: string) {
    setConfirmationPassword(value)
    setErros({
      ...errors,
      confirmationPassword: ''
    })

    if (value.length > 0) {
      if (value !== password) {
        setErros({
          ...errors,
          confirmationPassword: 'Confirmação de senha não coincide com a senha'
        })
      }
    }
  }

  const onSignUp = async () => {
    try {
      await createUserEmail.mutateAsync({
        name,
        password,
        email,
        confirmationPassword
      })

      setTimeout(() => {
        navigation.push('/')
      }, 3000)
    } catch (err) {
      if (err.response.status === 422) {
        setErros({
          ...errors,
          email: 'Email já cadastrado! Tente outro email.'
        })
      }
    }
  }

  useEffect(() => {
    const rule1 = !password || !email || !confirmationPassword || !name
    const rule2 =
      !!errors.email ||
      !!errors.password ||
      !!errors.confirmationPassword ||
      !!errors.name

    setActivedButton(rule1 || rule2)
  }, [errors, email, name, password, confirmationPassword])

  return (
    <PrimaryTemplate>
      <S.WrapperForm>
        <div className="wrapper-form--text">
          <HeadingArrowBack label="Criar conta" to="/" />
        </div>

        <div className="wrapper-form--forms">
          <div className="wrapper-form--forms--input">
            <Input
              iconLeft={IconUser}
              isDefault="default"
              label="nome"
              value={name}
              assistiveText={errors.name ? 'teste' : ''}
              state={errors.name ? 'danger' : 'default'}
              handleOnChange={(value: string) => handleInputName(value)}
            />
          </div>

          <div className="wrapper-form--forms--input">
            <Input
              iconLeft={IconMail}
              isDefault="default"
              label="e-mail"
              value={email}
              assistiveText={errors.email ? errors.email : ''}
              state={errors.email ? 'danger' : 'default'}
              handleOnChange={(value: string) => handleInputEmail(value)}
            />
          </div>

          <div className="wrapper-form--forms--input">
            <Input
              iconLeft={IconPassword}
              isDefault="default"
              label="senha"
              value={password}
              type="password"
              iconPassword
              assistiveText={errors.password ? errors.password : ''}
              state={errors.password ? 'danger' : 'default'}
              handleOnChange={(value: string) => handleInputPassword(value)}
            />
          </div>

          <div className="wrapper-form--forms--input">
            <Input
              iconLeft={IconPassword}
              isDefault="default"
              label="confirmar senha"
              value={confirmationPassword}
              type="password"
              iconPassword
              assistiveText={
                errors.confirmationPassword ? errors.confirmationPassword : ''
              }
              state={errors.confirmationPassword ? 'danger' : 'default'}
              handleOnChange={(value: string) =>
                handleInputConfirmationPassword(value)
              }
            />
          </div>

          <S.WrapperButton style={{ marginTop: 24 }}>
            <Button
              onClick={handleSubmit(onSignUp)}
              disabled={activedButton || formState.isSubmitting}
            >
              {formState.isSubmitting ? 'Carregando...' : 'CRIAR CONTA'}
            </Button>
          </S.WrapperButton>
        </div>
      </S.WrapperForm>

      <S.WrapperFooter>
        <p>
          Produto <span>maida.health</span>.
        </p>
      </S.WrapperFooter>
    </PrimaryTemplate>
  )
}
