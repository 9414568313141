import { AuthProvider } from 'hooks/auth'
import { MaidaUIProvider } from 'maida-ui'
import { BrowserRouter } from 'react-router-dom'

import { ReactQueryDevtools } from 'react-query/devtools'

import { Routes } from 'routes'

export function App() {
  return (
    <BrowserRouter>
      <MaidaUIProvider>
        <AuthProvider>
          <Routes />

          {/* <ReactQueryDevtools /> */}
        </AuthProvider>
      </MaidaUIProvider>
    </BrowserRouter>
  )
}
